import Sidebar from "components/Company/Sidebar";
import Footer from "components/Company/Footer";
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Button from '@material-tailwind/react/Button';
import { storeRestaurantOffer, resetRestaurantOffer } from "Services/action";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import loader from "assets/img/loading.gif";

const OfferAdd = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const inputFile = useRef(null);
    const [loading, setLoading] = useState(false);
    const [base64Image, setBase64Image] = useState('');

    const companyAdminData = useSelector((state) => state?.CompanyAdminDetail?.companyAdminDetailRec);

    const errorNotify = (error) =>
        toast.error(error, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();


    function onSubmit(offerData) {
        offerData.restaurant_id = companyAdminData?.id;
        dispatch(storeRestaurantOffer(offerData));
        dispatch(resetRestaurantOffer());
    }

    const initialStoredata = useSelector((state) => state?.RestaurantOfferStoreData);
    useEffect(() => {
        if (initialStoredata?.store_request == true) {
            setLoading(true);
        }
        if (initialStoredata?.success == true) {
            navigate('/restaurant/offerList')
        }
        if (initialStoredata?.error) {
            setLoading(false);
            let errorData = initialStoredata?.message
            errorNotify(errorData);
        }
    }, [initialStoredata])
   
    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="md:ml-32">
                <div className="px-3 md:px-8 h-auto mt-16">
                    <div className="container mx-auto max-w-full">
                        <div className="grid grid-cols-1 xl:grid-cols-6">
                            <div className="xl:col-start-1 xl:col-end-7 px-4 mb-16">
                                <div>
                                    <h2 className="head-cust-color">Add Offer</h2>
                                </div>
                                <Card className="bgcustsky rounded-none">
                                    <CardBody>
                                        <form className="custom-form form-student-set" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="flex flex-wrap mt-10">
                                                
                                               
                                                <div className="w-full lg:w-12/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" htmlFor="offer_description">
                                                        Offer Description
                                                        </label>
                                                        <textarea rows={5} {...register("offer_description", { required: true })} placeholder="Offer Description:" required className="w-full h-full focus:outline-none">
                                                        </textarea>
                                                        {errors.offer_description && <span className="error">Offer Description is required.</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <div className="w-full lg:w-6/12 pl-4 mt-5 mb-10 font-light btn-w-full">
                                                    <Button className="form-button" type="submit" disabled={loading && loading == true} >{loading && loading == true ? <img style={{ width: "25px", marginRight: "15px" }} src={loader} /> : null}
                                                        Submit</Button>
                                                </div>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}
export default OfferAdd
