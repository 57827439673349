import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import { updateProfile, getCompanyAdminDetails, resetUpdateProfileData } from "Services/action";
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from 'react';
import Button from '@material-tailwind/react/Button';
import { useNavigate } from 'react-router-dom';
import loader from "assets/img/loading.gif";
import { ToastContainer, toast } from "react-toastify";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { googleMapsApiKey } from 'Services/constant';

export default function SettingsForm() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [base64Image, setBase64Image] = useState('');
    const [address, setAddress] = useState('');
    const [latitude, setlatitude] = useState('');
    const [longitude, setlongitude] = useState('');
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [logoImg, setlogoImg] = useState();

    const errorNotify = (error) =>
        toast.error(error, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    const successNotify = (succesData) =>
        toast.success(succesData, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

        useEffect(() => {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`;
            script.async = true;
    
            script.onload = () => {
                setScriptLoaded(true);
            };
    
            script.onerror = () => {
                console.error('Error loading Google Maps API script');
                setScriptLoaded(false);
            };
    
            document.body.appendChild(script);
    
            return () => {
                document.body.removeChild(script);
            };
        }, [googleMapsApiKey]);

    const companyAdminData = useSelector((state) => state?.CompanyAdminDetail?.companyAdminDetailRec);
    const UpdateProfileData = useSelector((state) => state?.UpdateProfile);

    useEffect(() => {
        if (UpdateProfileData?.store_request == true) {
            setLoading(true);
        }
        if (UpdateProfileData?.success == true) {
            setLoading(false);

            let succesData = "Profile Updated Successfully"
            successNotify(succesData);
            const data = {
                id: companyAdminData?.id
            }
            dispatch(getCompanyAdminDetails(data));
            dispatch(resetUpdateProfileData())
        }
        if (UpdateProfileData?.error) {
            setLoading(false);
            let errorData = UpdateProfileData?.message
            errorNotify(errorData);
        }
    }, [UpdateProfileData])

    const ref = useRef(null);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        setAddress(companyAdminData?.address);
        setlatitude(companyAdminData?.latitude);
        setlongitude(companyAdminData?.longitude);
    }, [companyAdminData]);

    const handleAddressChange = (newAddress) => {
        setAddress(newAddress);
    };

    const handleSelect = (selectedAddress) => {
        geocodeByAddress(selectedAddress)
          .then((results) => getLatLng(results[0]))
          .then((latLng) => {
            setAddress(selectedAddress);
            setlatitude(latLng?.lat);
            setlongitude(latLng?.lng);
            // You need to define onAddressSelect or use another callback function here
          })
          .catch((error) => console.error('Error', error));
    };

    if (!scriptLoaded) {
        return <div>Loading Google Maps API...</div>; // or any other loading indicator
    }

    function onSubmit(companyData) {
        companyData.id = companyAdminData?.id;
        companyData.companyLogo = base64Image;
        companyData.address = address;
        companyData.latitude = latitude;
        companyData.longitude = longitude;
        dispatch(updateProfile(companyData));
    }

   

    function handleChange(e) {
        setlogoImg(URL.createObjectURL(e.target.files[0]));
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setBase64Image(reader.result);
            };
            reader.readAsDataURL(file);
        }
    }
    return (
        <Card>
            <ToastContainer />
            <CardHeader contentPosition="none">
                <div className="w-full flex items-center justify-between">
                    <h2 className="text-white text-2xl">Profile</h2>
                </div>
            </CardHeader>
            <CardBody>
                <form className='custom-form form-student-set' onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-wrap mt-10">
                        <div className="w-full lg:w-12/12 mb-10 font-light">
                            <div class="w-full relative h-11">
                                <label class="block text-black-700 text-sm font-bold ml-4" for="Name">Restaurant Name</label>
                                <input {...register("name", { required: true })}
                                    defaultValue={companyAdminData && companyAdminData?.name}
                                    placeholder="Names:" required className="w-full h-full focus:outline-none" />
                                {errors.name && <span className="error"> Name is required.</span>}
                            </div>
                        </div>

                        <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                            <div class="w-full relative h-11">
                                <label class="block text-black-700 text-sm font-bold ml-4" for="phone_no">Phone No.</label>

                                <input type="number" {...register("phone_no", {
                                    required: true,
                                    minLength: 10, // Minimum character length
                                    maxLength: 10, // Maximum character length
                                })}
                                    defaultValue={companyAdminData && companyAdminData?.phone_no}
                                    placeholder="Phone:" required className="w-full h-full remove-arrow focus:outline-none"
                                    onInput={(e) => {
                                        if (e.target.value.length > 10) {
                                            e.target.value = e.target.value.slice(0, 10); // Truncate the value to 10 characters
                                        }
                                    }} />
                                {errors.phone_no && <span className="error"> Phone is required.</span>}
                            </div>
                        </div>
                        <div className="w-full lg:w-6/12 pl-4 mb-10 font-light">
                            <div class="w-full relative h-11">
                                <label class="block text-black-700 text-sm font-bold ml-4" for="mobile_no">Mobile No.</label>

                                <input type="number" {...register("mobile_no", {
                                    required: true,
                                    minLength: 10, // Minimum character length
                                    maxLength: 10, // Maximum character length
                                })}
                                    defaultValue={companyAdminData && companyAdminData?.mobile_no}
                                    placeholder="mobile:" required className="w-full h-full remove-arrow focus:outline-none"
                                    onInput={(e) => {
                                        if (e.target.value.length > 10) {
                                            e.target.value = e.target.value.slice(0, 10); // Truncate the value to 10 characters
                                        }
                                    }} />
                                {errors.mobile_no && <span className="error"> Mobile No is required.</span>}
                            </div>
                        </div>
                        <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                            <div class="w-full relative h-11">
                                <label class="block text-black-700 text-sm font-bold ml-4" for="order_online_link">Order Online Link</label>
                                <input type="text" {...register("order_online_link", {required: true})}
                                    defaultValue={companyAdminData && companyAdminData?.order_online_link}
                                    placeholder="Order Online Link:" className="w-full h-full focus:outline-none" />
                                   {errors.order_online_link && <span className="error"> Order Online Link is required.</span>}
                            </div>
                        </div>
                        <div className="w-full lg:w-6/12 pl-4 mb-10 font-light">
                            <div class="w-full relative h-11">
                                <label class="block text-black-700 text-sm font-bold ml-4" for="reservation_link">Reservation/Booking</label>
                                <input type="text" {...register("reservation_link", {required: true})}
                                    defaultValue={companyAdminData && companyAdminData?.reservation_link}
                                    placeholder="Reservation Link:" className="w-full h-full focus:outline-none" />
                                    {errors.reservation_link && <span className="error"> Reservation Link is required.</span>}

                            </div>
                        </div>
                        <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                            <div class="w-full relative h-11">
                                <label class="block text-black-700 text-sm font-bold ml-4" for="website">Website</label>
                                <input type="text" {...register("website", {required: true})}
                                    defaultValue={companyAdminData && companyAdminData?.website}
                                    placeholder="Website:" className="w-full h-full focus:outline-none" />
                                    {errors.website && <span className="error"> Website is required.</span>}
                            </div>
                        </div>
                        <div className="w-full lg:w-12/12 mb-10 font-light">
                            <div class="w-full relative h-11">
                                <label class="block text-black-700 text-sm font-bold ml-4" for="points_value">How many points is = $5 (eg. when customer spends $40 (40 Point) he/she receive $5 rewards)</label>
                                <input type="text" {...register("points_value", {required: true})}
                                    defaultValue={companyAdminData && companyAdminData?.points_value}
                                    placeholder="40" className="w-full h-full focus:outline-none" />
                                {errors.points_value && <span className="error"> points value is required.</span>}

                            </div>
                        </div>

                        <div className="w-full lg:w-12/12 mb-10 font-light">
                        <label class="block text-black-700 text-sm font-bold ml-4" for="address">Address</label>
                            <PlacesAutocomplete
                                value={address}
                                onChange={handleAddressChange}
                                onSelect={handleSelect}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <input
                                            {...getInputProps({
                                                placeholder: 'Search Places ...',
                                                className: 'location-search-input w-full h-full focus:outline-none h-11',
                                            })}

                                        />


                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map((suggestion) => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                        </div>
                        <div className="w-full lg:w-12/12 pr-4 font-light">
                            <div class="w-full relative">
                                <label class="block text-black-700 text-sm font-bold ml-4" for="open_hours">Opening Hours : </label>
                                <textarea rows={7} {...register("open_hours", {required: true})} defaultValue={companyAdminData && companyAdminData?.open_hours} className="w-full h-full focus:outline-none" placeholder='Mon To Fri : 9AM to 5PM
Sat : 9AM to 5PM
Sun : 9AM to 5Pm'>

                                </textarea>
                                {errors.open_hours && <span className="error"> Open Hours is required.</span>}

                            </div>
                        </div>
                        <div className="w-full lg:w-12/12 pr-4 font-light">
                            <div class="w-full relative">
                                <label class="block text-black-700 text-sm font-bold ml-4" for="description">Description : </label>
                                <textarea rows={7} {...register("description")} defaultValue={companyAdminData && companyAdminData?.description} className="w-full h-full focus:outline-none" placeholder='description'></textarea>
                            </div>
                        </div>
                        <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                            <div class="w-full relative">
                                {companyAdminData?.companyLogo ? <img src={companyAdminData?.companyLogo} style={{ width: "100px", height: "100px", float: "left", marginRight: "20px" }} /> : null}

                                {logoImg ? <img src={logoImg} style={{ width: "100px", float: "left", marginRight: "20px" }} /> : null}
                                <input type="file" onChange={handleChange} className="w-full h-full focus:outline-none" />
                            </div>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="w-full lg:w-6/12 pr-4 mb-10 font-light btn-w-full">
                            <Button className="form-button" type="submit" disabled={loading && loading == true} >{loading && loading == true ? <img style={{ width: "25px", marginRight: "15px" }} src={loader} /> : null}
                                Submit</Button>
                        </div>
                    </div>
                </form>
            </CardBody>
        </Card>
    );
}
