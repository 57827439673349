import Card from '@material-tailwind/react/Card';
import Image from '@material-tailwind/react/Image';
import H5 from '@material-tailwind/react/Heading5';
import Icon from '@material-tailwind/react/Icon';
import Button from '@material-tailwind/react/Button';
import ProfilePicture from 'assets/img/logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import loader from "assets/img/loading.gif";
import { useEffect, useRef, useState } from 'react';
import { ToastContainer, toast } from "react-toastify";
import axios from 'axios';
import { API_URL } from "Services/constant";
export default function ProfileCard() {
    const companyAdminData = useSelector((state) => state?.CompanyAdminDetail?.companyAdminDetailRec);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const errorNotify = (error) =>
        toast.error(error, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    const successNotify = (succesData) =>
        toast.success(succesData, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    const ref = useRef(null);
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch
    } = useForm();

    const newPassword = watch("new_password");
    const confirmNewPassword = watch("confirm_new_password");

    function onSubmit(data) {
        data.id = companyAdminData?.id;
        data.phone_no = companyAdminData?.phone_no;
        console.log(companyAdminData);

        if (data.new_password !== data.confirm_new_password) {
            errorNotify("Passwords do not match.");
            return false;
        } else {
            data.phone_no = companyAdminData?.phone_no;
            data.password = data.new_password;
            setLoading(true);
            axios
                .post(`${API_URL}admin/companyUpdatePassword`, data)
                .then(async (response) => {
                    setLoading(false);
                    console.log('Delete response:', response.data);
                    successNotify(response.data.message);
                })
                .catch((error) => {
                    setLoading(false);
                    console.error('Error deleting', error);
                    errorNotify(error);
                });
        }
    }

    const validatePassword = (password) => {
        if (!password) {
            return true;
        }

        const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return strongPasswordRegex.test(password);
    };
    return (
        <div>
            <Card>
                <div className="flex flex-wrap justify-center">
                    <div className="w-48 px-4 -mt-24">
                        <Image src={companyAdminData && companyAdminData?.companyLogo ? companyAdminData?.companyLogo : ProfilePicture} rounded raised />
                    </div>
                </div>
                <div className="text-center">
                    <H5 color="gray">{companyAdminData?.name}</H5>
                    <div className="mb-2 text-gray-700 mt-10 flex items-center justify-center gap-2">
                        <Icon name="phone" size="xl" />
                        {companyAdminData?.phone_no}
                    </div>
                    <div className="mt-0 mb-2 text-gray-700 flex items-center justify-center gap-2">
                        <Icon name="place" size="xl" />
                        {companyAdminData?.address}
                    </div>
                </div>
            </Card>
            <Card className="mt-5">
                <form className='custom-form form-student-set' onSubmit={handleSubmit(onSubmit)}>
                    {errors.new_password && (
                        <span className="error">
                            {errors.new_password.type === "required"
                                ? "New Password is required."
                                : "New Password must be at least 8 characters, must contain a number, a smaller case letter and an uppercase letter and a special character."}
                        </span>
                    )}
                    {errors.confirm_new_password && <span className="error"> Confirm new password is required.</span>}
                    <div className="flex flex-wrap mt-10">
                        <div className="w-full lg:w-12/12 pr-4 mb-2 font-light">
                            <div class="w-full relative h-11">
                                <input {...register("new_password", { required: true, validate: validatePassword })} placeholder="New Password:" required className="w-full h-full focus:outline-none" />
                            </div>
                        </div>
                        <div className="w-full lg:w-12/12 pr-4 mt-2 font-light">
                            <div class="w-full relative h-11">
                                <input {...register("confirm_new_password", { required: true })} placeholder="Confirm Password:" required className="w-full h-full focus:outline-none" />
                            </div>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="w-full lg:w-6/12 pr-4 mt-10 font-light btn-w-full">
                            <Button className="form-button" type="submit" disabled={loading && loading == true} >{loading && loading == true ? <img style={{ width: "25px", marginRight: "15px" }} src={loader} /> : null} Submit</Button>
                        </div>
                    </div>
                </form>
            </Card>
        </div>
    );
}
