import { GETDETAILS, Get_User_Detail, Handle_User_Status, Reset_User_Status_Data, Handle_Company_Status,Reset_Company_Status_Data, Get_Local_Store_Data, Store_Multi_User, Get_Statistics,Reset_Mail_Forward_Data, Get_All_Report_For_Ceo } from "./constant"

// import for superadmin data
import { SuperAdminUserLogin, Reset_SuperAdmin_Login_Data, Reset_Login_Data, Update_Profile,Reset_Update_Profile_Data, Get_SuperAdmin_Local_Store_Data,Get_Super_Admin_Details, SuperAdminUserLogout } from "./constant"

// import for superadmin company Data
import { Get_Company, Store_Company, Reset_Company_Data, Update_Company } from "./constant"
 
import { Get_Wholesaler,Store_Wholesaler, Reset_Wholesaler_Data, Handle_Wholesaler_Status, Reset_Wholesaler_Status_Data, Update_Wholesaler } from "./constant"

import { Get_Job,Store_Job, Reset_Job_Data, Handle_Job_Status, Reset_Job_Status_Data, Update_Job } from "./constant"

import {Get_All_Members, Get_All_Employes, Get_All_Non_Card_Holder, Store_SuperAdmin_Employe,Reset_SuperAdmin_Employe_Data, Update_SuperAdmin_Employe} from "./constant"


// import for company Admin Data
import { AdminUserLogin, Get_Company_Admin_Details, AdminUserLogout, Mail_Forward } from "./constant"

import {Get_Employe_By_Company, Store_CompanyAdmin_Employe,Update_CompanyAdmin_Employe, Reset_CompanyAdmin_Employe_Data} from "./constant"

import {Get_Restaurant_Offer, Store_Restaurant_Offer, Reset_Restaurant_Offer, Update_Restaurant_Offer} from "./constant"

import {Get_Restaurant_Product_Offer, Store_Restaurant_Product_Offer, Reset_Restaurant_Product_Offer, Update_Restaurant_Product_Offer} from "./constant"

import {Get_Assistant_By_Company, Store_Company_Assistant, Update_Company_Assistant, Reset_Company_Assistant_Data, Delete_Company_Assistant} from "./constant"

import {Company_Forgot_Pass,Update_Password,Reset_Update_Password_Data, Reset_Company_Forgot_Pass_Data} from "./constant"

import {Get_Expired_Emp_By_Company, Reset_Company_Expired_Emp_Data} from "./constant"


export const GetDetails = (data) => {
    return {
        type: GETDETAILS,
        data
    }
}
export const getUserDetail = (data) => {
    return {
        type: Get_User_Detail,
        data: data
    }
}
export const handleUserStatus = (data) => {
    return {
        type: Handle_User_Status,
        data: data
    }
}
export const resetUserStatusData = () => {
    return {
        type: Reset_User_Status_Data
    }
}
export const handleCompanyStatus = (data) => {
    return {
        type: Handle_Company_Status,
        data: data
    }
}

export const resetCompanyStatusData = () => {
    return {
        type: Reset_Company_Status_Data
    }
}



export const updateProfile = (data) => {
    return {
        type: Update_Profile,
        data: data
    }
}
export const resetUpdateProfileData = () => {
    return {
        type: Reset_Update_Profile_Data
    }
}
export const getLocalStoreData = (data) => {
    return {
        type: Get_Local_Store_Data,
        data: data
    }
}
export const storeMultiUser = (data) => {
    return {
        type: Store_Multi_User,
        data: data
    }
}
export const getStatistics = (data) => {
    return {
        type: Get_Statistics,
        data: data
    }
}
export const resetMailForwardData = () => {
    return {
        type: Reset_Mail_Forward_Data
    }
}

export const mailForward = (data) => {
    return {
        type: Mail_Forward,
        data: data
    }
}

export const getAllReportForCeo = (data) => {
    return {
        type: Get_All_Report_For_Ceo,
        data: data
    }
}

///SuperAdmin Start
export const superAdminLogin = (data) => {
    return {
        type: SuperAdminUserLogin,
        data: data
    }
}
export const getSuperAdminLocalStoreData = (data) => {
    return {
        type: Get_SuperAdmin_Local_Store_Data,
        data: data
    }
}
export const resetSuperAdminLoginData = () => {
    return {
        type: Reset_SuperAdmin_Login_Data
    }
}
export const getSuperAdminDetails = (data) =>{
    return{
        type:Get_Super_Admin_Details,
        data:data 
    }  
}
export const storeCompany = (data) => {
    return {
        type: Store_Company,
        data: data
    }
}
export const updateCompany = (data) => {
    return {
        type: Update_Company,
        data: data
    }
}

export const resetCompanyData = () => {
    return {
        type: Reset_Company_Data
    }
}
export const getCompany = (data) => {
    return {
        type: Get_Company,
        data: data
    }
}

export const getWholesaler = (data) => {
    return {
        type: Get_Wholesaler,
        data: data
    }
}
export const storeWholesaler = (data) => {
    return {
        type: Store_Wholesaler,
        data: data
    }
}

export const resetWholesalerData = () => {
    return {
        type: Reset_Wholesaler_Data
    }
}

export const handleWholesalerStatus = (data) => {
    return {
        type: Handle_Wholesaler_Status,
        data: data
    }
}
export const resetWholesalerStatusData = () => {
    return {
        type: Reset_Wholesaler_Status_Data
    }
}


export const updateWholesaler = (data) => {
    return {
        type: Update_Wholesaler,
        data: data
    }
}


export const getJob = (data) => {
    return {
        type: Get_Job,
        data: data
    }
}
export const storeJob = (data) => {
    return {
        type: Store_Job,
        data: data
    }
}

export const resetJobData = () => {
    return {
        type: Reset_Job_Data
    }
}

export const handleJobStatus = (data) => {
    return {
        type: Handle_Job_Status,
        data: data
    }
}
export const resetJobStatusData = () => {
    return {
        type: Reset_Job_Status_Data
    }
}


export const updateJob = (data) => {
    return {
        type: Update_Job,
        data: data
    }
}

export const storeSuperAdminEmploye = (data) => {
    return {
        type: Store_SuperAdmin_Employe,
        data: data
    }
}

export const updateSuperAdminEmploye = (data) => {
    return {
        type: Update_SuperAdmin_Employe,
        data: data
    }
}


export const resetSuperAdminEmployeData = () => {
    return {
        type: Reset_SuperAdmin_Employe_Data
    }
}
export const getAllEmployes = (data) => {
    return {
        type: Get_All_Employes,
        data: data
    }
} 

export const getAllNonCardHolder = (data) => {
    return {
        type: Get_All_Non_Card_Holder,
        data: data
    }
} 
export const getAllMembers = (data) => {
    return {
        type: Get_All_Members,
        data: data
    }
} 

export const superAdminLogout = (data) => {
    return {
        type: SuperAdminUserLogout,
        data: data
    }
}
///SuperAdmin End

///Company Admin Start
export const adminLogin = (data) => {
    return {
        type: AdminUserLogin,
        data: data
    }
}
export const resetLoginData = () => {
    return {
        type: Reset_Login_Data
    }
}
export const companyForgotPass = (data) => {
    return {
        type: Company_Forgot_Pass,
        data: data
    }
}


export const resetCompanyForgotPassData = () => {
    return {
        type: Reset_Company_Forgot_Pass_Data
    }
}
export const updatePassword = (data) => {
    return {
        type: Update_Password,
        data: data
    }
}
export const resetUpdatePasswordData = () => {
    return {
        type: Reset_Update_Password_Data
    }
}
export const getCompanyAdminDetails = (data) =>{
    return{
        type:Get_Company_Admin_Details,
        data:data 
    }  
}
export const adminLogout = (data) => {
    return {
        type: AdminUserLogout,
        data: data
    }
}

export const storeCompanyAdminEmploye = (data) => {
    return {
        type: Store_CompanyAdmin_Employe,
        data: data
    }
}

export const GetEmployeByCompany = (data) => {
    return {
        type: Get_Employe_By_Company,
        data: data
    }
}

export const updateCompanyAdminEmploye = (data) => {
    return {
        type: Update_CompanyAdmin_Employe,
        data: data
    }
}

export const resetCompanyAdminEmployeData = () => {
    return {
        type: Reset_CompanyAdmin_Employe_Data
    }
}
export const GetRestaurantOffer = (data) => {
    return {
        type: Get_Restaurant_Offer,
        data: data
    }
}
export const storeRestaurantOffer = (data) => {
    return {
        type: Store_Restaurant_Offer,
        data: data
    }
}
export const resetRestaurantOffer = () => {
    return {
        type: Reset_Restaurant_Offer
    }
}
export const updateRestaurantOffer = (data) => {
    return {
        type: Update_Restaurant_Offer,
        data: data
    }
}

export const GetRestaurantProductOffer = (data) => {
    return {
        type: Get_Restaurant_Product_Offer,
        data: data
    }
}
export const storeRestaurantProductOffer = (data) => {
    return {
        type: Store_Restaurant_Product_Offer,
        data: data
    }
}
export const resetRestaurantProductOffer = () => {
    return {
        type: Reset_Restaurant_Product_Offer
    }
}
export const updateRestaurantProductOffer = (data) => {
    return {
        type: Update_Restaurant_Product_Offer,
        data: data
    }
}

export const GetExpiredEmpByCompany = (data) => {
    return {
        type: Get_Expired_Emp_By_Company,
        data: data
    }
}

export const resetCompanyExpiredEmpData = () => {
    return {
        type: Reset_Company_Expired_Emp_Data
    }
}

export const storeCompanyAssistant = (data) => {
    return {
        type: Store_Company_Assistant,
        data: data
    }
}



export const GetAssistantByCompany = (data) => {
    return {
        type: Get_Assistant_By_Company,
        data: data
    }
}

export const updateCompanyAssistant = (data) => {
    return {
        type: Update_Company_Assistant,
        data: data
    }
}

export const resetCompanyAssistantData = () => {
    return {
        type: Reset_Company_Assistant_Data
    }
}

export const deleteCompanyAssistant = () => {
    return {
        type: Delete_Company_Assistant
    }
}

