import Sidebar from "components/SuperAdmin/Sidebar";
import Footer from "components/SuperAdmin/Footer";
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Button from '@material-tailwind/react/Button';
//import { storeCompany, resetCompanyData } from "Services/action";
import { storeWholesaler, resetWholesalerData } from "Services/action";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import loader from "assets/img/loading.gif";

const AddRestaurant = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);



    const errorNotify = (error) =>
        toast.error(error, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    function onSubmit(wholesalerData) {
        dispatch(resetWholesalerData());
        dispatch(storeWholesaler(wholesalerData));
    }
    const initialStoredata = useSelector((state) => state.WholesalerStoreData);
    useEffect(() => {
        if (initialStoredata?.store_request == true) {
            setLoading(true);
        }
        if (initialStoredata?.success == true) {
            navigate('/superAdmin/wholesaler')
        }
        if (initialStoredata?.error) {
            setLoading(false);
            let errorData = initialStoredata?.message
            errorNotify(errorData);
        }
    }, [initialStoredata])

    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="md:ml-32">
                <div className="px-3 md:px-8 h-auto mt-16">
                    <div className="container mx-auto max-w-full">
                        <div className="grid grid-cols-1 xl:grid-cols-6">
                            <div className="xl:col-start-1 xl:col-end-7 px-4 mb-16">
                                <div>
                                    <h2 className="head-cust-color">Add Wholesaler</h2>
                                </div>
                                <Card className="bgcustsky rounded-none">
                                    <CardBody>
                                        <form className="custom-form form-student-set" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="flex flex-wrap mt-10">
                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="Name">
                                                            Name
                                                        </label>
                                                        <input {...register("name", { required: true })} placeholder="Name:" required className="w-full h-full focus:outline-none" />
                                                        {errors.name && <span className="error">Name is required.</span>}
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="phone_no">
                                                            Phone No.
                                                        </label>
                                                        <input type="number" {...register("phone_no", {
                                                            required: true,
                                                            minLength: 8, // Minimum character length
                                                            maxLength: 10, // Maximum character length
                                                        })} placeholder="Phone:" required className="w-full h-full remove-arrow focus:outline-none"
                                                            onInput={(e) => {
                                                                if (e.target.value.length > 10) {
                                                                    e.target.value = e.target.value.slice(0, 10); // Truncate the value to 10 characters
                                                                }
                                                            }} />
                                                        {errors.phone_no && <span className="error"> Phone is required.</span>}
                                                    </div>
                                                </div>


                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="whole_saler_type">Wholesaler Type</label>
                                                        <input type="text" {...register("whole_saler_type", { required: true })}
                                                            placeholder="Wholesaler Type:" className="w-full h-full focus:outline-none" />
                                                        {errors.whole_saler_type && <span className="error"> Wholesaler Type is required.</span>}
                                                    </div>
                                                </div>

                                                <div className="w-full lg:w-12/12 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="address">Address</label>
                                                        <input type="text" {...register("address", { required: true })}
                                                            placeholder="address" className="w-full h-full focus:outline-none" />
                                                        {errors.address && <span className="error"> Address is required.</span>}

                                                    </div>
                                                </div>


                                            </div>

                                            <div className="flex">
                                                <div className="w-full lg:w-6/12 mb-10 font-light btn-w-full">
                                                    <Button className="form-button" type="submit" disabled={loading && loading == true} >{loading && loading == true ? <img style={{ width: "25px", marginRight: "15px" }} src={loader} /> : null}
                                                        Submit</Button>                                                </div>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}
export default AddRestaurant
