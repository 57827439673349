import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import AdminNavbar from "./AdminNavbar";
import { adminLogout, getStatistics } from "../../Services/action";
import { useDispatch, useSelector } from "react-redux";
import LogoutImg from 'assets/img/new-setting.png';
import StudentIcon from 'assets/img/student.png';
import dashboard from 'assets/img/new-dashboard.png';

export default function Sidebar() { 
  const [showSidebar, setShowSidebar] = useState("-left-64");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [CurrentUserRole, setCurrentUserRole] = useState();

  const companyAdminData = useSelector((state) => state?.CompanyAdminDetail?.companyAdminDetailRec);
  const initialdata = useSelector((state) => state?.AdminLoginData);
  useEffect(() => {
    let admintokens = initialdata?.admintoken;
    if (admintokens && admintokens) {
      const decodedCompanyToken = decodeJwt(admintokens);
      setCurrentUserRole(decodedCompanyToken?.role)
    }
    if (admintokens == "" || admintokens == null) {
      navigate("/restaurant");
    }
  }, [initialdata]);
  function decodeJwt(token) {
    if (!token) {
      throw new Error('No token provided');
    }

    const tokenParts = token.split('.');
    if (tokenParts.length !== 3) {
      throw new Error('Invalid token format');
    }

    const base64Url = tokenParts[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }
  const logout = () => {
    dispatch(adminLogout());
    navigate("/restaurant");
  };
  useEffect(() => {
    const cData = {
      id: companyAdminData?.id
    }
    dispatch(getStatistics(cData))
  }, [companyAdminData]);

  return (
    <>
      <AdminNavbar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <div
        className={`h-screen custom-sidebar custom-sidebar fixed top-0 md:left-0 ${showSidebar} overflow-y-auto flex-row flex-nowrap overflow-hidden shadow-xl bg-white w-32 z-10 py-4 transition-all duration-300`}
      >
        <div className="flex-col items-stretch min-h-full flex-nowrap px-0 relative">
          <NavLink to="/restaurant/dashboard" className="mt-1 text-center w-full inline-block">
            <img className="w-16 h-16" src={dashboard} style={{ margin: "auto" }} />
            <span className="text-sm text-white font-bold">Dashboard</span>
          </NavLink>
          <div className="flex flex-col">
            <hr className="my-2 min-w-full" />
            <ul className="flex-col min-w-full flex list-none">
              {/* <li className="rounded-lg mb-2">
                <NavLink to="/restaurant/employeList" className="flex flex-col items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                  activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md">
                  <img src={StudentIcon} className="w-16 h-16" style={{ margin: "auto" }} />
                  <span className="text-sm text-white font-bold">Card Holders</span>
                </NavLink>
              </li>
              <hr className="my-2 min-w-full" /> */}
              <li className="rounded-lg mb-2">
                <NavLink to="/restaurant/offerList" className="flex flex-col items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                  activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md">
                  <img src={StudentIcon} className="w-16 h-16" style={{ margin: "auto" }} />
                  <span className="text-sm text-white font-bold">Offers</span>
                </NavLink>
              </li>
              <hr className="my-2 min-w-full" />
              <li className="rounded-lg mb-2">
                <NavLink to="/restaurant/productOfferList" className="flex flex-col items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                  activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md">
                  <img src={StudentIcon} className="w-16 h-16" style={{ margin: "auto" }} />
                  <span className="text-sm text-white font-bold">Product Offer</span>
                </NavLink>
              </li>
              <hr className="my-2 min-w-full" />
                  <li className="rounded-lg mb-2">
                    <NavLink
                      to="/restaurant/profile"
                      className="flex flex-col items-center text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                      activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                    >
                      <img src={LogoutImg} className="w-16 h-16 mb-2" alt="Profile Icon" />
                      <span className="text-sm text-white font-bold">Profile</span>
                    </NavLink>
                  </li>
                  <hr className="my-2 min-w-full" />
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
