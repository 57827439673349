import Sidebar from "components/SuperAdmin/Sidebar";
import Footer from "components/SuperAdmin/Footer";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { getAllMembers, resetSuperAdminEmployeData} from "Services/action";
import ReactPaginate from "react-paginate";
import loader from "assets/img/loading.gif";
import { CSVLink } from "react-csv";
import Arrowdown from 'assets/img/down-arrow.png';

const SuperAdminMemberList = () => {
  const dataFetchedRef = useRef(false);
  const navigate = useNavigate();



  const today = new Date(); // Current date

  const dispatch = useDispatch();
  const [EmployeList, setEmployeList] = useState([]);
  const [Search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [excelData, setExcelData] = useState([]);

  const perPageRecord = 10
  const handlePageClick = ({ selected }) => {
    const data = {
      name: Search,
      startDate: startDate,
      endDate: endDate,
      currentPage: selected,
      limit: perPageRecord
    }
    dispatch(getAllMembers(data));
  }

  useEffect(() => {
    const data = {
      currentPage: 0,
      limit: perPageRecord
    }
    dispatch(getAllMembers(data));
    dispatch(resetSuperAdminEmployeData());
  }, []);


  const filterData = () => {
    const data = {
      name: Search,
      startDate: startDate,
      endDate: endDate,
      status: status,
      currentPage: 0,
      limit: perPageRecord
    }
    dispatch(getAllMembers(data));
  }

  const resetfilterData = () => {
    setSearch('')
    setStatus('')
    setStartDate('')
    setEndDate('')
    const data = {
      currentPage: 0,
      limit: perPageRecord
    }
    dispatch(getAllMembers(data));
  }


  const initialdata = useSelector((state) => state?.MemberRecord?.memberRec?.result?.list);
  const totalCount = useSelector((state) => state?.MemberRecord?.memberRec?.result?.count);

  useEffect(() => {
    setEmployeList(initialdata);
    setLoading(true);

  }, [initialdata]);

 

  useEffect(() => {
    const columns = [
      {
        Name: "Name",
        LastName: "LastName",
        Email: "Email",
        PhoneNo: "PhoneNo",
      },
    ];
    setExcelData(columns);
  }, []);
  const getCsvData = () => {
    const csvData = [];

    if (EmployeList?.length > 0) {
      excelData.map((ex) => {
        csvData.push([
          `${ex.Name}`,
          `${ex.LastName}`,
          `${ex.Email}`,
          `${ex.PhoneNo}`,
        ]);
      });
      EmployeList?.map((val) => {
        csvData.push([
          `${val.name}`,
          `${val.lastname}`,
          `${val.email}`,
          `${val.phone_no}`,
        ]);
      });
    }
    return csvData;
  };

  return (
    <>
      <ToastContainer />
      <Sidebar />
      <div className="md:ml-32">
        <div className="px-3 md:px-8 h-auto mt-16">
          <div className="container mx-auto max-w-full">
            <div className="grid grid-cols-1 px-4 mb-16">
              <div class="grid grid-cols-2 gap-4">

                <div className="mb-5">
                  <h2 className="head-cust-color">Members - {totalCount && totalCount}</h2>
                </div>
                <div className="right-button-section">
                  <CSVLink filename="CardHolderList.csv" data={getCsvData()} >
                    {" "}
                    <button className="cust-button mb-3 mr-5" style={{ display: "inline-flex" }}>Export CSV <img src={Arrowdown} className="w-4 h-4" style={{ margin: "5px 0px 2px 5px" }} /> </button>
                  </CSVLink>
                </div>
              </div>
              <hr class="my-4 min-w-full"></hr>
              <div class="flex flex-wrap mb-4 mt-4">
                <div className="lg:w-3/12 ">
                  <input type="text" className="cust-input" placeholder="Search" value={Search}
                    onChange={(e) => setSearch(e.target.value)} />
                </div>
               
                {/* <div className="ml-2 mr-2 lg:w-2/12 ">
                  <input type="date" placeholder="From Date:" className="filterinput w-full h-11 focus:outline-none" value={startDate}
                    onChange={(e) => setStartDate(e.target.value)} style={{ border: '1px solid #797979', borderRadius: "25px" }} />
                </div>
                <div className="ml-2 mr-2 lg:w-2/12">
                  <input type="date" placeholder="To Date:" className="filterinput w-full h-11 focus:outline-none" value={endDate}
                    onChange={(e) => setEndDate(e.target.value)} style={{ border: '1px solid #797979', borderRadius: "25px" }} />
                </div> */}
                <div className="lg:w-2/12 ">
                  <button className="cust-button mb-3 ml-5" onClick={filterData}>Apply</button>
                  <button className="cust-button mb-3 ml-5" onClick={resetfilterData}>Reset</button>
                </div>

              </div>
              <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr className="px-6 py-4 font-medium text-blue-600 whitespace-nowrap dark:text-white">
                      <th scope="col" class="px-6 py-3">
                        First Name
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Last Name
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Email
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Phone
                      </th>
                      
                      {/* <th scope="col" class="px-6 py-3">
                        Status
                      </th> */}
                      {/* <th scope="col" class="px-6 py-3">
                        Action
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {EmployeList?.length && EmployeList[0]?.id != null ? (
                      EmployeList?.map((EmployeListVal, i) => {

                        return (
                          <tr
                            className="text-black border-b bg-white dark:bg-gray-800 dark:border-gray-700"
                            key={i}
                          >
                            <td className="px-6 py-4">
                              {EmployeListVal?.name}
                            </td>
                            <td className="px-6 py-4">
                              {EmployeListVal?.lastname}
                            </td>
                            <td className="px-6 py-4">
                              {EmployeListVal?.email}
                            </td>
                            <td class="px-6 py-4">
                              {EmployeListVal?.phone_no}
                            </td>
                            
                            {/* <td className="px-6 py-4">
                              <select onChange={(event) => handleSelectChange(event.target.value, EmployeListVal.id)}>
                                <option value="1" selected={EmployeListVal && EmployeListVal.status === 1}>Active</option>
                                <option value="0" selected={EmployeListVal && EmployeListVal.status === 0}>Deactive</option>
                              </select>
                            </td> */}
                            {/* <td className="px-6 py-4">
                              <button className="cust-button" onClick={() => navigate('/superAdmin/editEmploye', { state: { employeData: EmployeListVal } })}>Edit</button>

                            </td> */}
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 text-center" colSpan={7}>
                          No Record Found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <ReactPaginate
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={totalCount / perPageRecord}
                previousLabel="< previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default SuperAdminMemberList;
