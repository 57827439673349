import Sidebar from "components/SuperAdmin/Sidebar";
import Footer from "components/SuperAdmin/Footer";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { getCompany, resetCompanyData, handleCompanyStatus, resetCompanyStatusData } from "Services/action";
import loader from "assets/img/loading.gif";
import ReactPaginate from "react-paginate";

const Restaurants = () => {
  const dataFetchedRef = useRef(false);
  const navigate = useNavigate();
  const successNotify = (succesData) =>
    toast.success(succesData, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const userStatusUpdate = () =>
    toast.success("Status updated successfully !.", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const errorNotify = (error) =>
    toast.error(error, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const dispatch = useDispatch();
  const [Company, setCompany] = useState([]);
  const [Search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);

  const perPageRecord = 10
  const handlePageClick = ({ selected }) => {
    const data = {
      name: Search,
      status: status,
      currentPage: selected,
      limit: perPageRecord
    }
    dispatch(getCompany(data));
  }
  const filterData = () => {
    const data = {
      name: Search,
      status: status,
      currentPage: 0,
      limit: perPageRecord
    }
    dispatch(getCompany(data));
  }

  const resetfilterData = () => {
    setSearch('')
    setStatus('')
    const data = {
      currentPage: 0,
      limit: perPageRecord
    }
    dispatch(getCompany(data));
  }



  useEffect(() => {
    const data = {
      currentPage: 0,
      limit: perPageRecord
    }
    dispatch(getCompany(data));
    dispatch(resetCompanyData());
  }, []);

  const initialdata = useSelector((state) => state?.CompanyRecord?.companyRec?.result?.list);
  const totalCount = useSelector((state) => state?.CompanyRecord?.companyRec?.result?.count);

  const initialCompanyStatusdata = useSelector((state) => state?.handleCompanyStatusData);

  const initialStoredata = useSelector((state) => state?.CompanyStoreData);
  useEffect(() => {
    if (initialStoredata?.success) {
      let succesData = initialStoredata?.message
      successNotify(succesData);
    }
  }, [initialStoredata]);

  useEffect(() => {
    if (initialCompanyStatusdata?.success == true) {
      userStatusUpdate();
      dispatch(resetCompanyStatusData())
    }
    if (initialCompanyStatusdata?.error == true) {
      let errorData = initialCompanyStatusdata?.message
      errorNotify(errorData);
      dispatch(resetCompanyStatusData())

    }
  }, [initialCompanyStatusdata]);

  useEffect(() => {
    setCompany(initialdata);
    setLoading(true);
  }, [initialdata]);

  const handleSelectChange = (selectedOption, companyId, statusType) => {
    const data = {
      id: companyId,
      companyStatus: selectedOption,
      statusType:statusType
    }
    dispatch(handleCompanyStatus(data));
  };
  const handleOptionChange = (event) => {
    setStatus(event.target.value);
  };

  return (
    <>
      <ToastContainer />
      <Sidebar />
      <div className="md:ml-32">
        <div className="px-3 md:px-8 h-auto mt-16">
          <div className="container mx-auto max-w-full">
            <div className="grid grid-cols-1 px-4 mb-16">
              <div className="mb-5">
                <h2 className="head-cust-color">Restaurants - {totalCount && totalCount}</h2>
              </div>
              <div class="grid grid-cols-2 gap-4">
                <div>
                  <input type="text" className="cust-input" placeholder="Search" value={Search}
                    onChange={(e) => setSearch(e.target.value)} />

                  <select value={status} style={{ border: '1px solid #797979', borderRadius: "25px" }} className="filterinput cust-input ml-2 focus:outline-none"
                    onChange={handleOptionChange}>
                    <option value="">Select Status</option>
                    <option value="1">Active</option>
                    <option value="0">Deactive</option>
                  </select>

                  <button className="cust-button mb-3 ml-2" onClick={filterData}>Apply</button>
                  <button className="cust-button mb-3 ml-2" onClick={resetfilterData}>Reset</button>

                </div>
                <div className="right-button-section">
                  <NavLink to="/superAdmin/addRestaurant">
                    <button className="cust-button mb-3">Add +</button>
                  </NavLink>
                </div>
              </div>
              <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr className="px-6 py-4 font-medium text-blue-600 whitespace-nowrap dark:text-white">
                      <th scope="col" class="px-6 py-3">
                        Name
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Phone
                      </th>
                      {/* <th scope="col" class="px-6 py-3">
                        Expire Date
                      </th> */}
                      <th scope="col" class="px-6 py-3">
                        Address
                      </th>
                      
                      <th scope="col" class="px-6 py-3">
                        Status
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Company?.length && Company[0]?.id != null ? (
                      Company?.map((CompanyVal, i) => {
                        let date = new Date(CompanyVal?.token_expire_date);
                        /* Date format you have */
                        let dateMDY = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
                        /* Date converted to MM-DD-YYYY format */
                        return (
                          <tr
                            className="text-black border-b bg-white dark:bg-gray-800 dark:border-gray-700"
                            key={i}
                          >
                            <td className="px-6 py-4">
                              {CompanyVal?.name}
                            </td>
                            <td className="px-6 py-4">
                              {CompanyVal?.phone_no}
                            </td>
                            {/* <td className="px-6 py-4">
                              {dateMDY && dateMDY}
                            </td> */}
                            <td class="px-6 py-4">
                              {CompanyVal?.address} 
                            </td>
                            <td className="px-6 py-4">
                              <select onChange={(event) => handleSelectChange(event.target.value, CompanyVal.id,0)}>
                                <option value="1" selected={CompanyVal && CompanyVal?.status == 1}>Active</option>
                                <option value="0" selected={CompanyVal && CompanyVal?.status == 0}>Deactive</option>
                              </select>
                            </td>
                            <td class="px-6 py-4">
                              <button className="cust-button" onClick={() => navigate('/superAdmin/editRestaurant', { state: { companyData: CompanyVal } })}>Edit</button>
                            </td>

                          </tr>
                        );
                      })
                    ) : (
                      <tr className="bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 text-center" colSpan={7}>
                          No Record Found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <ReactPaginate
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={totalCount / perPageRecord}
                previousLabel="< previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default Restaurants;

