import Sidebar from "components/SuperAdmin/Sidebar";
import Footer from "components/SuperAdmin/Footer";
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Button from '@material-tailwind/react/Button';
import { storeCompany, resetCompanyData } from "Services/action";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import loader from "assets/img/loading.gif";
import LocationSearchInput from './LocationSearchInput';



const AddRestaurant = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [base64Image, setBase64Image] = useState('');
    const [randomString, setRandomString] = useState('');
    const [locationData, setLocationData] = useState({
        address: '',
        latitude: '',
        longitude: ''
    });

    const [showPassword, setShowPassword] = useState(false);

    const handleAddressSelect = ({ address, latitude, longitude }) => {
        setLocationData({
            address,
            latitude,
            longitude
        });
    };
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setBase64Image(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    const errorNotify = (error) =>
        toast.error(error, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();


    function onSubmit(companyData) {
        companyData.companyLogo = base64Image;
        companyData.address = locationData.address;
        companyData.latitude = locationData.latitude;
        companyData.longitude = locationData.longitude;
        companyData.access_token = randomString;

        dispatch(resetCompanyData());
        dispatch(storeCompany(companyData));
    }

    const validatePassword = (password) => {
        const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return strongPasswordRegex.test(password);
    };

    const initialStoredata = useSelector((state) => state.CompanyStoreData);
    useEffect(() => {

        if (initialStoredata.store_request == true) {
            setLoading(true);
        }
        if (initialStoredata.success == true) {
            navigate('/superAdmin/restaurants')
        }
        if (initialStoredata?.error) {
            setLoading(false);
            let errorData = initialStoredata?.message
            errorNotify(errorData);
        }
    }, [initialStoredata])
    const generateRandomString = () => {
        const length = 50; // You can adjust the length of the random string as needed
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            result += characters.charAt(randomIndex);
        }

        setRandomString(result);
    };


    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };



    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="md:ml-32">
                <div className="px-3 md:px-8 h-auto mt-16">
                    <div className="container mx-auto max-w-full">
                        <div className="grid grid-cols-1 xl:grid-cols-6">
                            <div className="xl:col-start-1 xl:col-end-7 px-4 mb-16">
                                <div>
                                    <h2 className="head-cust-color">Add Restaurant</h2>
                                </div>
                                <Card className="bgcustsky rounded-none">
                                    <CardBody>
                                        <form className="custom-form form-student-set" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="flex flex-wrap mt-10">
                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="Name">
                                                            Name
                                                        </label>
                                                        <input {...register("name", { required: true })} placeholder="Name:" required className="w-full h-full focus:outline-none" />
                                                        {errors.name && <span className="error">Name is required.</span>}
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-6/12 pl-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" htmlFor="password">
                                                            Please enter a strong password  (<a href="javascript:void(0)" title="Password must be at least 8 characters, must contain a number, a smaller case letter and an uppercase letter and a special character.">?</a>)
                                                        </label>
                                                        <input type="password" {...register("password", {
                                                            required: true,
                                                            minLength: 8,
                                                            validate: validatePassword
                                                        })} placeholder="Password" required className="w-full h-full remove-arrow focus:outline-none" />
                <button type="button" onClick={togglePasswordVisibility}>View</button>
                                                        {errors.password && (
                                                            <span className="error">
                                                                {errors.password.type === "required"
                                                                    ? "Password is required."
                                                                    : "Password must be at least 8 characters, must contain a number, a smaller case letter and an uppercase letter and a special character."}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="phone_no">
                                                            Phone No.
                                                        </label>
                                                        <input type="number" {...register("phone_no", {
                                                            required: true,
                                                            minLength: 8, // Minimum character length
                                                            maxLength: 10, // Maximum character length
                                                        })} placeholder="Phone:" required className="w-full h-full remove-arrow focus:outline-none"
                                                            onInput={(e) => {
                                                                if (e.target.value.length > 10) {
                                                                    e.target.value = e.target.value.slice(0, 10); // Truncate the value to 10 characters
                                                                }
                                                            }} />
                                                        {errors.phone_no && <span className="error"> Phone is required.</span>}
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-6/12 pl-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="mobile_no">
                                                            Mobile No.
                                                        </label>
                                                        <input type="text" {...register("mobile_no", {
                                                            required: true,
                                                            minLength: 8, // Minimum character length
                                                            maxLength: 10, // Maximum character length
                                                        })} placeholder="Mobile:" required className="w-full h-full remove-arrow focus:outline-none"
                                                            onInput={(e) => {
                                                                if (e.target.value.length > 10) {
                                                                    e.target.value = e.target.value.slice(0, 10); // Truncate the value to 10 characters
                                                                }
                                                            }} />
                                                        {errors.mobile_no && <span className="error"> Mobile no is required.</span>}
                                                    </div>
                                                </div>

                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="order_online_link">Order Online Link</label>
                                                        <input type="text" {...register("order_online_link", { required: true })}
                                                            placeholder="Order Online Link:" className="w-full h-full focus:outline-none" />
                                                        {errors.order_online_link && <span className="error"> Order Online Link is required.</span>}
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-6/12 pl-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="reservation_link">Reservation/Booking</label>
                                                        <input type="text" {...register("reservation_link", { required: true })}
                                                            placeholder="Reservation Link:" className="w-full h-full focus:outline-none" />
                                                        {errors.reservation_link && <span className="error"> Reservation Link is required.</span>}

                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="website">Website</label>
                                                        <input type="text" {...register("website", { required: true })}
                                                            placeholder="Website:" className="w-full h-full focus:outline-none" />
                                                        {errors.website && <span className="error"> Website is required.</span>}
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-12/12 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="points_value">How many points is = $1 (eg. when customer spends $40 (40 Point) he/she receive $1 rewards)</label>
                                                        <input type="text" {...register("points_value", { required: true })}
                                                            placeholder="40" className="w-full h-full focus:outline-none" />
                                                        {errors.points_value && <span className="error"> points value is required.</span>}

                                                    </div>
                                                </div>

                                                <div className="w-full lg:w-12/12 pr-4 mb-10 font-light">

                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="address">
                                                            Address
                                                        </label>
                                                        <LocationSearchInput onAddressSelect={handleAddressSelect} />
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="token_expire_date">
                                                            Token Expire Date
                                                        </label>
                                                        <input type="date" {...register("token_expire_date", { required: true })} placeholder="Token Expire Date:" required className="w-full h-full focus:outline-none" />
                                                        {errors.token_expire_date && <span className="error"> Expire Date is required.</span>}
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-10/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="access_token">
                                                            Access Token
                                                        </label>
                                                        <input type="text" {...register("access_token")} value={randomString} placeholder="Access Token:" className="w-full h-full focus:outline-none" />
                                                        {/* {errors.access_token && <span className="error"> Access token is required.</span>} */}
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-2/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <Button type="button" className="form-button" onClick={generateRandomString}>Generate Token</Button>
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-8/12 pr-4 mb-10 font-light">
                                                    <label class="block text-black-700 text-sm font-bold ml-4" for="Company Logo">
                                                        Company Logo.
                                                    </label>
                                                    <input type="file" accept="image/*" onChange={handleImageChange} required />
                                                </div>

                                                <div className="w-full lg:w-12/12 pr-4 font-light">
                                                    <div class="w-full relative">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="open_hours">Opening Hours : </label>
                                                        <textarea rows={7} {...register("open_hours", { required: true })} className="w-full h-full focus:outline-none" placeholder='Mon To Fri : 9AM to 5PM
Sat : 9AM to 5PM
Sun : 9AM to 5Pm'>

                                                        </textarea>
                                                        {errors.open_hours && <span className="error"> Open Hours is required.</span>}

                                                    </div>
                                                </div>

                                                <div className="w-full lg:w-12/12 pr-4 font-light">
                                                    <div class="w-full relative">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="description">Description : </label>
                                                        <textarea rows={7} {...register("description")} className="w-full h-full focus:outline-none" placeholder='Description'></textarea>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="flex">
                                                <div className="w-full lg:w-6/12 mb-10 font-light btn-w-full">
                                                    <Button className="form-button" type="submit" disabled={loading && loading == true} >{loading && loading == true ? <img style={{ width: "25px", marginRight: "15px" }} src={loader} /> : null}
                                                        Submit</Button>                                                </div>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}
export default AddRestaurant
