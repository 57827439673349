import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getStatistics } from 'Services/action';
import { useNavigate } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Card from '@material-tailwind/react/Card';
import CardRow from '@material-tailwind/react/CardRow';
import CardStatus from '@material-tailwind/react/CardStatus';
import StudentIcon from 'assets/img/student.png';
import { useLocation } from 'react-router-dom';
import Button from '@material-tailwind/react/Button';

const MainStatusCard = () => {
    const companyAdminData = useSelector((state) => state?.CompanyAdminDetail?.companyAdminDetailRec);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const baseUrl = "https://hr.tradieid.net.au";
    const queryString = `company_id=${companyAdminData?.id}`;

    // Encode the baseUrl and queryString
    const encodedBaseUrl = encodeURIComponent(baseUrl);
    const encodedQueryString = encodeURIComponent(queryString);
    const chartUrl = `https://chart.googleapis.com/chart?cht=qr&chl=${encodedBaseUrl}?${encodedQueryString}&chs=525x525&chld=H|0`;
    
    const [TotalEmployee, setTotalEmployee] = useState(0)
    const [TotalOffers, setTotalOffers] = useState(0)
    const [TotalProductOffers, setTotalProductOffers] = useState(0)

    
    useEffect(() => {
        const data = {
            id: companyAdminData?.id
        }
        dispatch(getStatistics(data))
    }, [])

    const StaticsRecord = useSelector((state) => state?.StaticsRecord?.staticsRec);
    useEffect(() => {
        setTotalEmployee(StaticsRecord?.totalCompanyEmploye)
        setTotalOffers(StaticsRecord?.totalCompanyOffers)
        setTotalProductOffers(StaticsRecord?.totalCompanyProductOffers)

        
        
    }, [StaticsRecord])

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const Print = () => {
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        // Create a container to center the content
        let printContainer = document.createElement('div');
        printContainer.style.textAlign = 'center';
        printContainer.style.margin = 'auto';
        printContainer.style.width = '50%'; // You can adjust the width as needed
        // Set the inner HTML of the container
        printContainer.innerHTML = printContents;
        // Replace the body content with the centered container
        document.body.innerHTML = '';
        document.body.appendChild(printContainer);
        // Print the content
        window.print();
        // Restore the original contents
        document.body.innerHTML = originalContents;
    }
    return (
        <>
            <Carousel responsive={responsive}>
                <div className="px-6 mb-10 main-tiles-section">
                    <NavLink to="/restaurant/offerList">
                        <Card className="main-tiles p-0">
                            <CardRow className="inner-tiles">
                                <CardStatus className="tiles-title" title={TotalOffers && TotalOffers ? TotalOffers : 0} />
                                <img src={StudentIcon} className="w-24 h-24" />
                                <CardStatus className="tiles-title-bottom" title={"Offers"} />
                            </CardRow>
                        </Card>
                    </NavLink>
                </div>

                <div className="px-6 mb-10 main-tiles-section">
                    <NavLink to="/restaurant/productOfferList">
                        <Card className="main-tiles p-0">
                            <CardRow className="inner-tiles">
                                <CardStatus className="tiles-title" title={TotalProductOffers && TotalProductOffers ? TotalProductOffers : 0} />
                                <img src={StudentIcon} className="w-24 h-24" />
                                <CardStatus className="tiles-title-bottom" title={"Product Offers"} />
                            </CardRow>
                        </Card>
                    </NavLink>
                </div>

                <div className="px-6 mb-10 main-tiles-section">
                    <div className="text-center" id='printablediv'>
                        <img style={{ width: '300px', height: '300px' }} src={chartUrl} title="Restaurant Qr" />
                    </div>
                    <Button className="form-button" type="button" onClick={Print} >Print</Button>
                </div>
            </Carousel>
        </>
    )
}
export default MainStatusCard