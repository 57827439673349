import Card from '@material-tailwind/react/Card';
import Image from '@material-tailwind/react/Image';
import H5 from '@material-tailwind/react/Heading5';
import Icon from '@material-tailwind/react/Icon';
import ProfilePicture from 'assets/img/logo.png';
import { useSelector } from 'react-redux';

export default function ProfileCard() {
    const superAdminDetail = useSelector((state) => state?.SuperAdminDetail?.superAdminDetailRec);
    return (
        <Card>
            <div className="flex flex-wrap justify-center">
                <div className="w-48 px-4 -mt-24">
                    <Image src={superAdminDetail && superAdminDetail?.companyLogo ? superAdminDetail?.companyLogo : ProfilePicture} rounded raised />
                </div>
            </div>
            <div className="text-center">
                <H5 color="gray">{superAdminDetail && superAdminDetail?.name}</H5>
                <div className="mt-0 mb-2 text-gray-700 flex items-center justify-center gap-2">
                    <Icon name="place" size="xl" />
                    {superAdminDetail && superAdminDetail?.address} 
                </div>
                <div className="mb-2 text-gray-700 mt-0 flex items-center justify-center gap-2">
                    <Icon name="work" size="xl" />
                    Hunger Reward Manager
                </div>
            </div>
        </Card>
    );
}
