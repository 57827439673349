import Sidebar from "components/SuperAdmin/Sidebar";
import Footer from "components/SuperAdmin/Footer";
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Button from '@material-tailwind/react/Button';
import { updateJob, resetJobData } from "Services/action";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import loader from "assets/img/loading.gif";

// Import other necessary components and styles
const EditRestaurant = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const jobDetail = location?.state?.jobData;
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();

    const errorNotify = (error) =>
        toast.error(error, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    const initialStoredata = useSelector((state) => state.JobStoreData);

    useEffect(() => {
        if (initialStoredata.store_request === true) {
            setLoading(true);
        }
        if (initialStoredata.success === true) {
            navigate('/superAdmin/jobs');
        }
        if (initialStoredata?.error) {
            setLoading(false);
            let errorData = initialStoredata?.message;
            errorNotify(errorData);
        }
    }, [initialStoredata, navigate]);

    function onSubmit(jobData) {
        jobData.id = jobDetail?.id;
        dispatch(resetJobData());
        dispatch(updateJob(jobData));
    }

    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="md:ml-32">
                <div className="px-3 md:px-8 h-auto mt-16">
                    <div className="container mx-auto max-w-full">
                        <div className="grid grid-cols-1 xl:grid-cols-6">
                            <div className="xl:col-start-1 xl:col-end-7 px-4 mb-16">
                                <div>
                                    <h2 className="head-cust-color">Edit Job</h2>
                                </div>
                                <Card className="bgcustsky rounded-none">
                                    <CardBody>
                                        <form className="custom-form form-student-set" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="flex flex-wrap mt-10">

                                                <div className="w-full lg:w-12/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="job_title">
                                                        Job Title
                                                        </label>
                                                        <input {...register("job_title", { required: true })} defaultValue={jobDetail?.job_title} placeholder="Job Title:" required className="w-full h-full focus:outline-none" />
                                                        {errors.job_title && <span className="error">Job Title is required.</span>}
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-12/12 pr-4 font-light">
                                                    <div class="w-full relative">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="job_description">Description : </label>
                                                        <textarea rows={7} {...register("job_description")} defaultValue={jobDetail && jobDetail?.job_description} className="w-full h-full focus:outline-none" placeholder='Description'></textarea>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="flex">
                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light btn-w-full">
                                                    <Button className="form-button" type="submit" disabled={loading && loading == true} >{loading && loading == true ? <img style={{ width: "25px", marginRight: "15px" }} src={loader} /> : null}
                                                        Submit</Button>                                                 </div>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default EditRestaurant;
