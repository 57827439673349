import React from "react";
import Home from "pages/Front/Home";
import SuperAdminLogin from "pages/SuperAdmin/Login";
import SuperAdminDashboard from 'pages/SuperAdmin/Dashboard';
import SuperAdminProfile from 'pages/SuperAdmin/Profile';

//superadmin Restaurant start
import Restaurants from 'pages/SuperAdmin/Restaurant/Restaurants';
import AddRestaurant from 'pages/SuperAdmin/Restaurant/AddRestaurant';
import RestaurantDetails from 'pages/SuperAdmin/Restaurant/RestaurantDetails';
import EditRestaurant from 'pages/SuperAdmin/Restaurant/EditRestaurant';

import Wholesaler from 'pages/SuperAdmin/Wholesaler/Wholesalers';
import AddWholesaler from 'pages/SuperAdmin/Wholesaler/AddWholesaler';
import EditWholesaler from 'pages/SuperAdmin/Wholesaler/EditWholesaler';

import Jobs from 'pages/SuperAdmin/Job/Jobs';
import AddJob from 'pages/SuperAdmin/Job/AddJob';
import EditJob from 'pages/SuperAdmin/Job/EditJob';

//superadmin Restaurant End
//superadmin Member start
import SuperAdminMemberList from 'pages/SuperAdmin/Employe/Members';
//superadmin Member End
//superadmin Employe start

import SuperAdminEmployeList from 'pages/SuperAdmin/Employe/Employes';
import SuperAdminSelfRegisteredEmployeList from 'pages/SuperAdmin/Employe/SelfRegisteredEmploye';
import SuperAdminAddEmployes from 'pages/SuperAdmin/Employe/AddEmploye';
import SuperAdminEditEmployes from 'pages/SuperAdmin/Employe/EditEmploye';
import SuperAdminAddMultiEmployes from 'pages/SuperAdmin/Employe/AddMultiEmploye';
import SuperAdminEmployeDetails from 'pages/SuperAdmin/Employe/EmployeDetails';
 //superadmin Employe end




//Company Admin Start
import CompanyLogin from "pages/Company/Login";
import CompanyForgotPassword from "pages/Company/CompanyForgotPassword";


import Dashboard from 'pages/Company/Dashboard';
import Profile from 'pages/Company/Profile';
//Company Admin End


//Company Admin Employe Routes start
import EmployeAdd from "pages/Company/Employe/EmployeAdd";
import EmployeList from 'pages/Company/Employe/EmployeList';
import ExpiredEmpList from 'pages/Company/Employe/ExpiredEmpList';
import EmployeDetail from 'pages/Company/Employe/EmployeDetail';
import EmployeEdit from "pages/Company/Employe/EmployeEdit";
import MultiEmplyeAdd from "pages/Company/Employe/MultiEmplyeAdd";
//Company Admin Employe Routes end



//Company Admin Offers Routes start
import OfferAdd from "pages/Company/Offers/OfferAdd";
import OfferList from 'pages/Company/Offers/OfferList';
import OfferEdit from "pages/Company/Offers/OfferEdit";
//Company Admin Offers Routes end

//Company Admin Offers Routes start
import ProductOfferAdd from "pages/Company/Offers/ProductOfferAdd";
import ProductOfferList from 'pages/Company/Offers/ProductOfferList';
import ProductOfferEdit from "pages/Company/Offers/ProductOfferEdit";
//Company Admin Offers Routes end



import { Route, Routes } from "react-router-dom";
const WebRoutes = () => {
    return (
        <Routes>
            {/*
             SuperAdmin Routes Start
            */}
            <Route path="/" index element={<SuperAdminLogin />} />
            <Route path="/superAdmin" index element={<SuperAdminLogin />} />
            <Route path="/superAdmin/dashboard" index element={<SuperAdminDashboard />} />
            <Route path="/superAdmin/profile" index element={<SuperAdminProfile />} />
            <Route path="/superAdmin/restaurants" index element={<Restaurants />} />
            <Route path="/superAdmin/addRestaurant" index element={<AddRestaurant />} />
            <Route path="/superAdmin/editRestaurant" index element={<EditRestaurant />} />
            <Route path="/superAdmin/companyDetails" index element={<RestaurantDetails />} />

            <Route path="/superAdmin/memberList" index element={<SuperAdminMemberList />} />

            <Route path="/superAdmin/employeList" index element={<SuperAdminEmployeList />} />
            <Route path="/superAdmin/selfRegisterdEmployeList" index element={<SuperAdminSelfRegisteredEmployeList />} />

            <Route path="/superAdmin/addEmploye" index element={<SuperAdminAddEmployes  />} />
            <Route path="/superAdmin/employeDetails" index element={<SuperAdminEmployeDetails />} />
            <Route path="/superAdmin/editEmploye" index element={<SuperAdminEditEmployes  />} />

            <Route path="/superAdmin/wholesaler" index element={<Wholesaler />} />
            <Route path="/superAdmin/addWholesaler" index element={<AddWholesaler />} />
            <Route path="/superAdmin/editWholesaler" index element={<EditWholesaler />} />

            <Route path="/superAdmin/Jobs" index element={<Jobs />} />
            <Route path="/superAdmin/addJob" index element={<AddJob />} />
            <Route path="/superAdmin/editJob" index element={<EditJob />} />
            

            <Route path="/superAdmin/addMultiEmploye" index element={<SuperAdminAddMultiEmployes  />} />

            {/*
             Company Admin Routes Start
            */}
            <Route path="/restaurant" index element={<CompanyLogin />} />
            <Route path="/restaurant/forgotPassword" index element={<CompanyForgotPassword />} />
            <Route path="/restaurant/dashboard" index element={<Dashboard />} />
            <Route path="/restaurant/profile" index element={<Profile />} />
            <Route path="/restaurant/employeAdd" index element={<EmployeAdd />} />
            <Route path="/restaurant/employeList" index element={<EmployeList />} />
            <Route path="/restaurant/expiredEmpList" index element={<ExpiredEmpList />} />
            <Route path="/restaurant/employeDetail" index element={<EmployeDetail />} />
            <Route path="/restaurant/employeEdit" index element={<EmployeEdit />} />
            <Route path="/restaurant/multiEmployeAdd" index element={<MultiEmplyeAdd  />} />

            <Route path="/restaurant/offerAdd" index element={<OfferAdd />} />
            <Route path="/restaurant/offerList" index element={<OfferList />} />
            <Route path="/restaurant/offerEdit" index element={<OfferEdit />} />


            <Route path="/restaurant/productOfferAdd" index element={<ProductOfferAdd />} />
            <Route path="/restaurant/productOfferList" index element={<ProductOfferList />} />
            <Route path="/restaurant/productOfferEdit" index element={<ProductOfferEdit />} />

            

        </Routes>
    )
}
export default WebRoutes