import Sidebar from "components/Company/Sidebar";
import Footer from "components/Company/Footer";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetRestaurantOffer, resetRestaurantOffer, handleUserStatus, resetUserStatusData } from "Services/action";

import { useEffect, useState, useRef } from "react";
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import loader from "assets/img/loading.gif";
import axios from 'axios';
import { API_URL } from "Services/constant";
import ReactPaginate from "react-paginate";
const OfferList = () => {

  const successNotify = (succesData) =>
    toast.success(succesData, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const userStatusUpdate = () =>
    toast.success("Status updated successfully !.", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [EmployeList, setEmployeList] = useState([]);
  const [Search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [excelData, setExcelData] = useState([]);

  const companyAdminData = useSelector((state) => state?.CompanyAdminDetail?.companyAdminDetailRec);
  const initialLoginData = useSelector((state) => state?.AdminLoginData);

  let companyToken = initialLoginData?.admintoken;

  useEffect(() => {
    if (companyToken != '' && companyToken != null && companyToken !== "undefined") {
      const decodedCompanyToken = decodeJwt(companyToken);
    }
  }, [companyToken])

  function decodeJwt(token) {
    if (!token) {
      throw new Error('No token provided');
    }
    const tokenParts = token.split('.');
    if (tokenParts.length !== 3) {
      throw new Error('Invalid token format');
    }
    const base64Url = tokenParts[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    return JSON.parse(jsonPayload);
  }

  const perPageRecord = 10;
  const handlePageClick = ({ selected }) => {
    const data = {
      id: companyAdminData?.id,
      name: Search,
      status: status,
      startDate: startDate,
      endDate: endDate,
      currentPage: selected,
      limit: perPageRecord
    }
    dispatch(GetRestaurantOffer(data));
  }

  const handleDeleteClick = (id) => {
    if (window.confirm('Are you sure you want to delete this employee?')) {
      axios
        .delete(`${API_URL}admin/deleteEmployee/${id}`)
        .then(async (response) => {
          console.log('Delete response:', response.data);
          successNotify(response.data.message);
          await resetfilterData();
        })
        .catch((error) => {
          console.error('Error deleting employee', error);
        });
    }
  };

  const filterData = () => {
    const data = {
      id: companyAdminData?.id,
      name: Search,
      status: status,
      startDate: startDate,
      endDate: endDate,
      currentPage: 0,
      limit: perPageRecord,
    };
    dispatch(GetRestaurantOffer(data));
  };
  const resetfilterData = () => {
    setSearch('')
    setStatus('')
    setStartDate('')
    setEndDate('')
    const data = {
      id: companyAdminData?.id,
      currentPage: 0,
      limit: perPageRecord
    }
    dispatch(GetRestaurantOffer(data));
  }

  useEffect(() => {
    const data = {
      id: companyAdminData?.id,
      currentPage: 0,
      limit: perPageRecord
    }
    dispatch(GetRestaurantOffer(data));
    dispatch(resetRestaurantOffer());
  }, []);
  const initialdata = useSelector((state) => state?.RestaurantOfferRecord?.restaurantOfferRec?.result?.list);
  const totalCount = useSelector((state) => state?.RestaurantOfferRecord?.restaurantOfferRec?.result?.count);
  const initialUserStatusdata = useSelector((state) => state.handleUserStatusData);
  const initialStoredata = useSelector((state) => state?.RestaurantOfferStoreData);

  useEffect(() => {
    if (initialStoredata?.success) {
      let succesData = initialStoredata?.message
      successNotify(succesData);
    }

  }, [initialStoredata]);

  useEffect(() => {
    setEmployeList(initialdata);
    //setLoading(true);
  }, [initialdata]);

  useEffect(() => {
    if (initialUserStatusdata.success == true) {
      userStatusUpdate();
      dispatch(resetUserStatusData())
    }
  }, [initialUserStatusdata]);

  const handleSelectChange = (selectedOption, empId) => {
    const data = {
      id: empId,
      status: selectedOption,
    }
    dispatch(handleUserStatus(data));
  };

  const handleOptionChange = (event) => {
    setStatus(event.target.value);
  };

  const today = new Date(); // Current date
  return (
    <>
      <ToastContainer />
      <Sidebar />
      <div className="md:ml-32">
        <div className="px-3 md:px-8 h-auto mt-16">
          <div className="container mx-auto max-w-full">
            <div className="grid grid-cols-1 px-4 mb-16">
              <div class="flex flex-wrap">
                <div className="w-full lg:w-6/12">
                  <h2 className="head-cust-color">Offers - {totalCount && totalCount}</h2>
                </div>
                <div className="w-full lg:w-6/12">
                  <div className="right-button-section">

                  </div>
                </div>
              </div>
              <div className="right-button-section">
                {totalCount < 4 && (
                  <NavLink to="/restaurant/offerAdd">
                    <button className="cust-button mb-3">Add +</button>
                  </NavLink>
                )}
              </div>
              <hr class="my-4 min-w-full"></hr>
              <div class="flex flex-wrap mb-4 mt-4">
                <div className="lg:w-3/12 ">
                  <input type="text" className="cust-input" placeholder="Search" value={Search}
                    onChange={(e) => setSearch(e.target.value)} />
                </div>

                {/* <div className="ml-2 mr-2 lg:w-1/12 ">
                  <select id="filterstatus" value={status} style={{ border: '1px solid #797979', borderRadius: "25px" }} className="filterinput w-full h-11 focus:outline-none"
                    onChange={handleOptionChange} >
                    <option value="">Status</option>
                    <option value="1">Active</option>
                    <option value="0">Deactive</option>
                  </select>
                </div> */}
                <div className="lg:w-3/12 ">
                  <button className="cust-button mb-3 ml-5" onClick={filterData}>Apply</button>
                  <button className="cust-button mb-3 ml-5" onClick={resetfilterData}>Reset</button>
                </div>
              </div>
              <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr className="px-6 py-4 font-medium text-blue-600 whitespace-nowrap dark:text-white">

                      <th scope="col" class="px-6 py-3">
                        Offer Description
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Status
                      </th>
                      {/* <th scope="col" class="px-6 py-3">
                        Action
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {EmployeList?.length && EmployeList[0]?.id != null ? (
                      EmployeList?.map((EmployeListVal, i) => {
                        return (
                          <tr className={`text-black border-b dark:border-gray-700 ${EmployeListVal?.status == 0
                            ? "bg-yellow-600 " : "bg-white dark:bg-gray-800"
                            }`}
                            key={i}
                          >
                            <td className="px-6 py-4">
                              {EmployeListVal?.offer_description}
                            </td>
                            {/* <td className="px-6 py-4">
                              <select onChange={(event) => handleSelectChange(event.target.value, EmployeListVal.id)}>
                                <option value="1" selected={EmployeListVal && EmployeListVal.status == 1}>Active</option>
                                <option value="0" selected={EmployeListVal && EmployeListVal.status == 0}>Inactive</option>
                              </select>
                            </td> */}
                            <td className="flex px-6 py-4">
                              <button className="cust-button" onClick={() => navigate('/restaurant/offerEdit', { state: { employeData: EmployeListVal } })}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                              </svg>
                              </button>&nbsp;&nbsp;
                              {/* <button className="cust-button" onClick={() => handleDeleteClick(EmployeListVal?.id)}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                              </svg>
                              </button> */}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 text-center" colSpan={3}>
                          No Record Found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <ReactPaginate
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={totalCount / perPageRecord}
                previousLabel="< previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default OfferList;
