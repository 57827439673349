import Sidebar from "components/SuperAdmin/Sidebar";
import Footer from "components/SuperAdmin/Footer";
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Button from '@material-tailwind/react/Button';
import { updateCompany, resetCompanyData } from "Services/action";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import loader from "assets/img/loading.gif";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { googleMapsApiKey } from 'Services/constant';

// Import other necessary components and styles
const EditRestaurant = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const location = useLocation();
    const companyDetail = location?.state?.companyData;

    const inputFile = useRef(null);
    const [loading, setLoading] = useState(false);
    const [base64Image, setBase64Image] = useState('');
    const [logoImg, setlogoImg] = useState('');
    const [address, setAddress] = useState('');
    const [latitude, setlatitude] = useState('');
    const [longitude, setlongitude] = useState('');

    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleImageChange = (e) => {
        setlogoImg(URL.createObjectURL(e.target.files[0]));

        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setBase64Image(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const errorNotify = (error) =>
        toast.error(error, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`;
        script.async = true;

        script.onload = () => {
            console.log('Google Maps API script loaded successfully');
            setScriptLoaded(true);
        };

        script.onerror = () => {
            console.error('Error loading Google Maps API script');
            setScriptLoaded(false);
        };

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [googleMapsApiKey]);

    const validatePassword = (password) => {
        const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return strongPasswordRegex.test(password);
    };
    const initialStoredata = useSelector((state) => state.CompanyStoreData);

    useEffect(() => {
        if (initialStoredata.store_request === true) {
            setLoading(true);
        }
        if (initialStoredata.success === true) {
            navigate('/superAdmin/restaurants');
        }
        if (initialStoredata?.error) {
            setLoading(false);
            let errorData = initialStoredata?.message;
            errorNotify(errorData);
        }
    }, [initialStoredata, navigate]);


    useEffect(() => {
        setAddress(companyDetail?.address);
        setlatitude(companyDetail?.latitude);
        setlongitude(companyDetail?.longitude);
    }, [companyDetail]);

    const handleChange = (newAddress) => {
        setAddress(newAddress);
    };

    const handleSelect = (selectedAddress) => {
        geocodeByAddress(selectedAddress)
            .then((results) => getLatLng(results[0]))
            .then((latLng) => {
                setAddress(selectedAddress);
                setlatitude(latLng?.lat);
                setlongitude(latLng?.lng);
                // You need to define onAddressSelect or use another callback function here
            })
            .catch((error) => console.error('Error', error));
    };

    if (!scriptLoaded) {
        return <div>Loading Google Maps API...</div>; // or any other loading indicator
    }

    function onSubmit(companyData) {
        companyData.id = companyDetail?.id;
        companyData.companyLogo = base64Image;
        companyData.address = address;
        companyData.latitude = latitude;
        companyData.longitude = longitude;
        dispatch(resetCompanyData());
        dispatch(updateCompany(companyData));
    }

    let dateMDY = "";
    if (companyDetail?.token_expire_date) {
        let date = new Date(companyDetail.token_expire_date);
        dateMDY = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }

    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="md:ml-32">
                <div className="px-3 md:px-8 h-auto mt-16">
                    <div className="container mx-auto max-w-full">
                        <div className="grid grid-cols-1 xl:grid-cols-6">
                            <div className="xl:col-start-1 xl:col-end-7 px-4 mb-16">
                                <div>
                                    <h2 className="head-cust-color">Edit Restaurant</h2>
                                </div>
                                <Card className="bgcustsky rounded-none">
                                    <CardBody>
                                        <form className="custom-form form-student-set" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="flex flex-wrap mt-10">

                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="Name">
                                                            Name
                                                        </label>
                                                        <input {...register("name", { required: true })} defaultValue={companyDetail?.name} placeholder="Name:" required className="w-full h-full focus:outline-none" />
                                                        {errors.name && <span className="error">Name is required.</span>}
                                                    </div>
                                                </div>

                                                <div className="w-full lg:w-5/12 pl-4 mb-10 font-light">
                                                    <div className="w-full relative h-11">
                                                        <label className="block text-black-700 text-sm font-bold ml-4" htmlFor="password">
                                                            Please enter a strong password (<a href="javascript:void(0)" title="Password must be at least 8 characters, must contain a number, a smaller case letter and an uppercase letter and a special character.">?</a>)
                                                        </label>
                                                        <input
                                                            type={showPassword ? 'text' : 'password'}
                                                            {...register("password", {
                                                                required: true,
                                                                minLength: 8,
                                                                validate: validatePassword
                                                            })}
                                                            defaultValue={companyDetail?.originalPassword}
                                                            placeholder="Password"
                                                            required
                                                            className="w-full h-full focus:outline-none"
                                                        />
                                                        {errors.password && (
                                                            <span className="error">
                                                                {errors.password.type === "required"
                                                                    ? "Password is required."
                                                                    : "Password must be at least 8 characters, must contain a number, a smaller case letter and an uppercase letter and a special character."}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-1/12 pl-4 mt-6 font-light">
                                                <div className="w-full relative h-11">
                                                    <button type="button" onClick={togglePasswordVisibility}>View</button>
                                                        </div>
                                                    </div>
                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="phone_no">
                                                            Phone No.
                                                        </label>
                                                        <input type="number" {...register("phone_no", { required: true })}
                                                            defaultValue={companyDetail?.phone_no} className="w-full h-full remove-arrow focus:outline-none"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-6/12 pl-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="phone_no">
                                                            Mobile No.
                                                        </label>
                                                        <input type="text" {...register("mobile_no", { required: true })}
                                                            defaultValue={companyDetail?.mobile_no} className="w-full h-full remove-arrow focus:outline-none"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="order_online_link">Order Online Link</label>
                                                        <input type="text" {...register("order_online_link", { required: true })}
                                                            defaultValue={companyDetail && companyDetail?.order_online_link}
                                                            placeholder="Order Online Link:" className="w-full h-full focus:outline-none" />
                                                        {errors.order_online_link && <span className="error"> Order Online Link is required.</span>}
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-6/12 pl-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="reservation_link">Reservation/Booking</label>
                                                        <input type="text" {...register("reservation_link", { required: true })}
                                                            defaultValue={companyDetail && companyDetail?.reservation_link}
                                                            placeholder="Reservation Link:" className="w-full h-full focus:outline-none" />
                                                        {errors.reservation_link && <span className="error"> Reservation Link is required.</span>}

                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="website">Website</label>
                                                        <input type="text" {...register("website", { required: true })}
                                                            defaultValue={companyDetail && companyDetail?.website}
                                                            placeholder="Website:" className="w-full h-full focus:outline-none" />
                                                        {errors.website && <span className="error"> Website is required.</span>}
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-12/12 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="points_value">How many points is = $1 (eg. when customer spends $40 (40 Point) he/she receive $1 rewards)</label>
                                                        <input type="text" {...register("points_value", { required: true })}
                                                            defaultValue={companyDetail && companyDetail?.points_value}
                                                            placeholder="40" className="w-full h-full focus:outline-none" />
                                                        {errors.points_value && <span className="error"> points value is required.</span>}

                                                    </div>
                                                </div>


                                                <div className="w-full lg:w-12/12 pr-4 mb-10 font-light">
                                                    <label class="block text-black-700 text-sm font-bold ml-4" for="phone_no">
                                                        Address.
                                                    </label>
                                                    <PlacesAutocomplete
                                                        value={address}
                                                        onChange={handleChange}
                                                        onSelect={handleSelect}
                                                    >
                                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                            <div>
                                                                <input
                                                                    {...getInputProps({
                                                                        placeholder: 'Search Places ...',
                                                                        className: 'location-search-input w-full h-full focus:outline-none h-11',
                                                                    })}
                                                                />
                                                                <div className="autocomplete-dropdown-container">
                                                                    {loading && <div>Loading...</div>}
                                                                    {suggestions.map((suggestion) => {
                                                                        const className = suggestion.active
                                                                            ? 'suggestion-item--active'
                                                                            : 'suggestion-item';
                                                                        const style = suggestion.active
                                                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                                        return (
                                                                            <div
                                                                                {...getSuggestionItemProps(suggestion, {
                                                                                    className,
                                                                                    style,
                                                                                })}
                                                                            >
                                                                                <span>{suggestion.description}</span>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </PlacesAutocomplete>
                                                </div>
                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="access_token">
                                                            Access Token
                                                        </label>
                                                        <input type="text" value={companyDetail?.access_token} placeholder="Access Token:" className="w-full h-full focus:outline-none" readOnly />
                                                    </div>
                                                </div>
                                                <div className="w-full lg:w-6/12 pl-4 mb-10 font-light">
                                                    <div class="w-full relative h-11">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="token_expire_date">
                                                            Token Expire Date
                                                        </label>

                                                        <input
                                                            type="text"
                                                            defaultValue={dateMDY}
                                                            placeholder="Token Expire Date:"
                                                            className="w-full h-full focus:outline-none"
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>


                                                <div className="w-full lg:w-12/12 pl-4 mb-10 font-light">
                                                    <div class="w-full relative">

                                                        {companyDetail?.companyLogo ? <img src={companyDetail?.companyLogo} style={{ width: "100px", height: "100px", float: "left", marginRight: "20px" }} /> : null}
                                                        {logoImg ? <img src={logoImg} style={{ width: "100px", height: "100px", float: "left", marginRight: "20px" }} /> : null}

                                                    </div>

                                                </div>
                                                <div className="w-full lg:w-12/12 pl-4 mb-10 font-light">
                                                    <div class="w-full relative">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="companyLogo">
                                                            Company Logo
                                                        </label>
                                                        <input type="file" className="mt-2" accept="image/*" onChange={handleImageChange} />
                                                    </div>

                                                </div>

                                                <div className="w-full lg:w-12/12 pr-4 font-light">
                                                    <div class="w-full relative">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="open_hours">Opening Hours : </label>
                                                        <textarea rows={7} {...register("open_hours", { required: true })} defaultValue={companyDetail && companyDetail?.open_hours} className="w-full h-full focus:outline-none" placeholder='Mon To Fri : 9AM to 5PM
Sat : 9AM to 5PM
Sun : 9AM to 5Pm'>
                                                        </textarea>
                                                        {errors.open_hours && <span className="error"> Open Hours is required.</span>}

                                                    </div>
                                                </div>

                                                <div className="w-full lg:w-12/12 pr-4 font-light">
                                                    <div class="w-full relative">
                                                        <label class="block text-black-700 text-sm font-bold ml-4" for="description">Description : </label>
                                                        <textarea rows={7} {...register("description")} defaultValue={companyDetail && companyDetail?.description} className="w-full h-full focus:outline-none" placeholder='Description'></textarea>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="flex">
                                                <div className="w-full lg:w-6/12 pr-4 mb-10 font-light btn-w-full">
                                                    <Button className="form-button" type="submit" disabled={loading && loading == true} >{loading && loading == true ? <img style={{ width: "25px", marginRight: "15px" }} src={loader} /> : null}
                                                        Submit</Button>                                                 </div>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default EditRestaurant;
