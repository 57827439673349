import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import firebase from '../../firebase';
import { companyForgotPass, updatePassword, resetUpdatePasswordData, resetCompanyForgotPassData } from "../../Services/action";
import ProfilePicture from 'assets/img/logo.png';
import Image from '@material-tailwind/react/Image';
import { ToastContainer, toast } from 'react-toastify';
import Button from '@material-tailwind/react/Button';
import loader from "assets/img/loading.gif";


const CompanyForgotPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [phoneFormVisible, setPhoneFormVisible] = useState(true);
    const [otpFormVisible, setOtpFormVisible] = useState(false);
    const [newPasswordFormVisible, setNewPasswordFormVisible] = useState(false);

    const inputValueRef = useRef(null);
    const initialdata = useSelector((state) => state?.CompanyForgotPass);
    const initialUpdatePasswordData = useSelector((state) => state?.UpdatePassword);


    const errorNotify = (error) =>
        toast.error(error, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    const successNotify = (succesData) =>
        toast.success(succesData, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    useEffect(() => {
        if (initialdata?.store_request == true) {
            setLoading(true);
        }
        if (initialdata?.success == true) {
            sendOtp();
        }
        if (initialdata?.error) {
            dispatch(resetCompanyForgotPassData());
            setLoading(false);
            let errorData = initialdata?.message
            errorNotify(errorData);
        }
    }, [initialdata])

    useEffect(() => {
        if (initialUpdatePasswordData?.store_request == true) {
            setLoading(true);
        }
        if (initialUpdatePasswordData?.success == true) {
            navigate('/restaurant')
        }
        if (initialUpdatePasswordData?.error) {
            setLoading(false);
            let errorData = initialUpdatePasswordData?.message
            errorNotify(errorData);
            dispatch(resetUpdatePasswordData());
        }
    }, [initialUpdatePasswordData])



    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const [state, setState] = useState({
        mobile: '',
        otp: '',
    });

    const configureCaptcha = () => {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
            size: 'invisible',
            callback: (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                onSignInSubmit();
                //console.log('Recaptcha verified');
            },
            defaultCountry: 'IN',
        });
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setState({
            ...state,
            [name]: value,
        });
    };

    const onSignInSubmit = (data) => {
        data.mobile = state?.mobile;
        dispatch(companyForgotPass(data));
        configureCaptcha();
    };
    const getAppVerifier = () => window.recaptchaVerifier;
    const sendOtp = () => {
        const phoneNoVal = state.mobile;
        const phoneNumber = '+91' + phoneNoVal;
        const appVerifier = getAppVerifier();
        firebase
            .auth()
            .signInWithPhoneNumber(phoneNumber, appVerifier)
            .then((confirmationResult) => {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                window.confirmationResult = confirmationResult;
                console.log('OTP has been sent');

                setLoading(false);
                let succesData = initialdata?.message
                successNotify(succesData);
                setPhoneFormVisible(false);
                setOtpFormVisible(true);
                dispatch(resetCompanyForgotPassData());
            })
            .catch((error) => {
                console.log('SMS not sent', error);
            });
    }

    const onSubmitOTP = (e) => {
        e.preventDefault();
        const code = state.otp;
        console.log(code);
        window.confirmationResult
            .confirm(code)
            .then((result) => {
                // User signed in successfully.
                const user = result.user;
                console.log(JSON.stringify(user));
                //alert('User is verified');

                setOtpFormVisible(false);
                setNewPasswordFormVisible(true);

                // ...
            })
            .catch((error) => {
                // User couldn't sign in (bad verification code?)
                // ...
                //console.log("OTP Mismatch");
                let errorData = "OTP Mismatch";
                errorNotify(errorData);
            });
    };

    function submitNewPassword(data) {
        dispatch(updatePassword(data));
        dispatch(resetUpdatePasswordData());
    }

    return (
        <>
            <ToastContainer />
            <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8">

                    {phoneFormVisible && (
                        <><div>
                            <Image className="mx-auto h-12 w-1/4" src={ProfilePicture} />
                            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Forgot Password</h2>
                        </div>
                            <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSignInSubmit)}>
                                <div className="flex flex-wrap mt-10">
                                    <div id="sign-in-button"></div>
                                    <div className="w-full lg:w-12/12 mb-10 font-light">
                                        <div className="w-full relative h-11">
                                            <label className="form-label">Enter Phone</label>
                                            <input type="number" {...register("mobile", { required: true })} onChange={handleChange} placeholder="Phone No" className="w-full h-full focus:outline-none border border-gray-300 focus:border-indigo-500" />
                                            {errors.mobile && <span className="error"> Phone No is required.</span>}
                                        </div>
                                    </div>
                                    <Button className="form-button" type="submit" disabled={loading && loading == true}>{loading && loading == true ? <img style={{ width: "25px", marginRight: "15px" }} src={loader} /> : null}
                                        Send OTP
                                    </Button>
                                </div>
                            </form>
                        </>
                    )}
                    {otpFormVisible && (
                        <><div>
                        <Image className="mx-auto h-12 w-1/4" src={ProfilePicture} />
                        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Enter OTP</h2>
                    </div>
                        <form id="otpForm" className="mt-8 space-y-6" onSubmit={onSubmitOTP}>
                            <div className="flex flex-wrap mt-10">
                                <div className="w-full lg:w-12/12 mb-10 font-light">
                                    <div className="w-full relative h-11">
                                        <label className="form-label">Enter OTP</label>
                                        <input
                                            type="number"
                                            name="otp"
                                            placeholder="OTP Number"
                                            required
                                            onChange={handleChange}
                                            className="w-full h-full focus:outline-none border border-gray-300 focus:border-indigo-500"
                                        />
                                    </div>
                                </div>
                                <Button className="form-button" type="submit">
                                    Submit
                                </Button>
                            </div>
                        </form>
                        </>
                    )}
                    {newPasswordFormVisible && (
                        <><div>
                        <Image className="mx-auto h-12 w-1/4" src={ProfilePicture} />
                        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Set New Password</h2>
                    </div>
                        <form className="mt-8 space-y-6" onSubmit={handleSubmit(submitNewPassword)} >
                            <div className="flex flex-wrap mt-10">
                                <div className="w-full lg:w-12/12 mb-10 font-light">
                                    <div className="w-full relative h-11">
                                        <label className="form-label">Enter Password</label>
                                        <input defaultValue={state?.mobile} type="hidden" {...register("phone_no")} />
                                        <input type="text" {...register("password", {
                                            required: true,
                                            minLength: 8, // Minimum character length
                                            maxLength: 8,
                                        })} placeholder="Password" required className="w-full h-full focus:outline-none" />
                                        {errors.password && <span className="error"> Required Password should be 8 digit.</span>}
                                    </div>
                                </div>
                                <Button className="form-button" type="submit">
                                    Set Password
                                </Button>
                            </div>
                        </form>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default CompanyForgotPassword;
