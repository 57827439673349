import Sidebar from "components/SuperAdmin/Sidebar";
import Footer from "components/SuperAdmin/Footer";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { getAllNonCardHolder} from "Services/action";
import ReactPaginate from "react-paginate";
import loader from "assets/img/loading.gif";

const SuperSelfRegisteredEmployeList = () => {
    const dataFetchedRef = useRef(false);
    const navigate = useNavigate();
   

    const dispatch = useDispatch();
    const [EmployeList, setEmployeList] = useState([]);
    const [Search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);

    const perPageRecord = 10
    const handlePageClick = ({ selected }) => {
        const data = {
            name: Search,
            currentPage: selected,
            limit: perPageRecord
        }
        dispatch(getAllNonCardHolder(data));
    }

    useEffect(() => {
        const data = {
            currentPage: 0,
            limit: perPageRecord
        }
        dispatch(getAllNonCardHolder(data));
    }, []);


    const filterData = () => {
        const data = {
            name: Search,
            currentPage: 0,
            limit: perPageRecord
        }
        dispatch(getAllNonCardHolder(data));
    }

    const resetfilterData = () => {
        setSearch('')
        const data = {
            currentPage: 0,
            limit: perPageRecord
        }
        dispatch(getAllNonCardHolder(data));
    }
    const initialdata = useSelector((state) => state?.NonCardHolderRecord?.nonCardHolderRec?.result?.list);
    const totalCount = useSelector((state) => state?.NonCardHolderRecord?.nonCardHolderRec?.result?.count);
    
    useEffect(() => {
        setEmployeList(initialdata);
        setLoading(true);
    }, [initialdata]);

    return (
        <>
            <ToastContainer />
            <Sidebar />
            <div className="md:ml-32">
                <div className="px-3 md:px-8 h-auto mt-16">
                    <div className="container mx-auto max-w-full">
                        <div className="grid grid-cols-1 px-4 mb-16">
                            <div className="mb-5">
                                <h2 className="head-cust-color">Non Card Holders - {totalCount && totalCount}</h2>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <input type="text" className="cust-input" placeholder="Search" value={Search}
                                        onChange={(e) => setSearch(e.target.value)} />
                                    <button className="cust-button mb-3 ml-5" onClick={filterData}>Apply</button>
                                    <button className="cust-button mb-3 ml-5" onClick={resetfilterData}>Reset</button>

                                </div>
                                <div className="right-button-section">

                                </div>
                            </div>
                            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                        <tr className="px-6 py-4 font-medium text-blue-600 whitespace-nowrap dark:text-white">
                                            <th scope="col" className="px-6 py-3">
                                                Name
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Last Name
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Email
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Phone
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {EmployeList && EmployeList?.length && EmployeList[0]?.id != null  ? EmployeList?.map((EmployeListVal, i) => (
                                            <tr class={i / 2 === 0 ? "text-black border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700" : "text-black border-b bg-white dark:bg-gray-800 dark:border-gray-700"} key={i}>
                                                <td className="px-6 py-4">
                                                    {EmployeListVal?.name}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {EmployeListVal?.lastname}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {EmployeListVal?.email}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {EmployeListVal?.phone_no}
                                                </td>
                                               
                                            </tr>
                                        )) :
                                            <tr className="bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-600">
                                                <td className="px-6 py-4 text-center" colSpan={4}>
                                                    No Record Found.
                                                </td>
                                            </tr>}
                                    </tbody>
                                </table>
                            </div>
                            <ReactPaginate
                                nextLabel="next >"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={2}
                                pageCount={totalCount / perPageRecord}
                                previousLabel="< previous"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};
export default SuperSelfRegisteredEmployeList;
