import Sidebar from "components/SuperAdmin/Sidebar";
import Footer from "components/SuperAdmin/Footer";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { getCompany, getAllEmployes, resetSuperAdminEmployeData } from "Services/action";
import ReactPaginate from "react-paginate";
import loader from "assets/img/loading.gif";
import { CSVLink } from "react-csv";
import Arrowdown from 'assets/img/down-arrow.png';

const SuperEmployeList = () => {
  const dataFetchedRef = useRef(false);
  const navigate = useNavigate();

  const today = new Date(); // Current date
  const dispatch = useDispatch();
  const [EmployeList, setEmployeList] = useState([]);
  const [RestaurantList, setRestaurantList] = useState([]);


  const [Search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [searchRestaurant, setSearchRestaurant] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [excelData, setExcelData] = useState([]);

  const perPageRecord = 10
  const handlePageClick = ({ selected }) => {
    const data = {
      name: Search,
      companyAssigned: 1,
      startDate: startDate,
      endDate: endDate,
      currentPage: selected,
      limit: perPageRecord
    }
    dispatch(getAllEmployes(data));
  }

  useEffect(() => {
    const companyData = {
      currentPage: 0,
      limit: 10000,
    }
    dispatch(getCompany(companyData));

    const data = {
      currentPage: 0,
      companyAssigned: 1,
      limit: perPageRecord
    }

    dispatch(getAllEmployes(data));
    dispatch(resetSuperAdminEmployeData());
  }, []);


  const filterData = () => {
    const data = {
      name: Search,
      startDate: startDate,
      endDate: endDate,
      status: status,
      searchRestaurant:searchRestaurant,
      currentPage: 0,
      companyAssigned: 1,
      limit: perPageRecord
    }
    dispatch(getAllEmployes(data));
  }

  const resetfilterData = () => {
    setSearch('')
    setStatus('')
    setSearchRestaurant('')
    setStartDate('')
    setEndDate('')
    const data = {
      currentPage: 0,
      companyAssigned: 1,
      limit: perPageRecord
    }
    dispatch(getAllEmployes(data));
  }

  const initialdata = useSelector((state) => state?.EmployeRecord?.employeRec?.result?.list);
  const totalCount = useSelector((state) => state?.EmployeRecord?.employeRec?.result?.count);
  const restaurantData = useSelector((state) => state?.CompanyRecord?.companyRec?.result?.list);

  useEffect(() => {
    setEmployeList(initialdata);
    setLoading(true);

  }, [initialdata]);

  useEffect(() => {
    setRestaurantList(restaurantData);
  }, [restaurantData]);



  const handleOptionChange = (event) => {
    //setStatus(event.target.value);
    setSearchRestaurant(event.target.value);
  };


  useEffect(() => {
    const columns = [
      {
        Name: "Name",
        LastName: "LastName",
        Email: "Email",
        PhoneNo: "PhoneNo",
        Card: "Card",
        Restaurant: "Restaurant",
      },
    ];
    setExcelData(columns);
  }, []);
  const getCsvData = () => {
    const csvData = [];

    if (EmployeList?.length > 0) {
      excelData.map((ex) => {
        csvData.push([
          `${ex.Name}`,
          `${ex.LastName}`,
          `${ex.Email}`,
          `${ex.PhoneNo}`,
          `${ex.Card}`,
          `${ex.Restaurant}`,
        ]);
      });
      EmployeList?.map((val) => {
        csvData.push([
          `${val.name}`,
          `${val.lastname}`,
          `${val.email}`,
          `${val.phone_no}`,
          `${val.card_no}`,
          `${val.restaurantName}`,
        ]);
      });
    }
    return csvData;
  };

  return (
    <>
      <ToastContainer />
      <Sidebar />
      <div className="md:ml-32">
        <div className="px-3 md:px-8 h-auto mt-16">
          <div className="container mx-auto max-w-full">
            <div className="grid grid-cols-1 px-4 mb-16">
              <div class="grid grid-cols-2 gap-4">

                <div className="mb-5">
                  <h2 className="head-cust-color">Card Holder - {totalCount && totalCount}</h2>
                </div>
                <div className="right-button-section">
                  <CSVLink filename="CardHolderList.csv" data={getCsvData()} >
                    {" "}
                    <button className="cust-button mb-3 mr-5" style={{ display: "inline-flex" }}>Export CSV <img src={Arrowdown} className="w-4 h-4" style={{ margin: "5px 0px 2px 5px" }} /> </button>
                  </CSVLink>
                  {/* <NavLink to="/superAdmin/addMultiEmploye">
                    <button className="cust-button mb-3 mr-5">Upload CSV +</button>
                  </NavLink> */}

                  {/* <NavLink to="/superAdmin/addEmploye">
                    <button className="cust-button mb-3">Add +</button>
                  </NavLink> */}

                </div>
              </div>
              <hr class="my-4 min-w-full"></hr>
              <div class="flex flex-wrap mb-4 mt-4">
                <div className="lg:w-3/12 ">
                  <input type="text" className="cust-input" placeholder="Search" value={Search}
                    onChange={(e) => setSearch(e.target.value)} />
                </div>

                <div className="ml-2 mr-2 lg:w-2/12 ">
                  <select value={searchRestaurant} 
                    id="filterstatus"
                    style={{ border: '1px solid #797979', borderRadius: '25px' }}
                    className="filterinput w-full h-11 focus:outline-none"
                    onChange={handleOptionChange}
                  >
                    <option value="">Select Restaurant</option>
                    {RestaurantList?.length > 0 &&
                      RestaurantList.map((RestaurantVal) => (
                        <option key={RestaurantVal.name} value={RestaurantVal.id}>
                          {RestaurantVal.name}
                        </option>
                      ))}
                  </select>

                </div>


                <div className="ml-2 mr-2 lg:w-2/12 ">
                  <input type="date" placeholder="From Date:" className="filterinput w-full h-11 focus:outline-none" value={startDate}
                    onChange={(e) => setStartDate(e.target.value)} style={{ border: '1px solid #797979', borderRadius: "25px" }} />
                </div>
                <div className="ml-2 mr-2 lg:w-2/12">
                  <input type="date" placeholder="To Date:" className="filterinput w-full h-11 focus:outline-none" value={endDate}
                    onChange={(e) => setEndDate(e.target.value)} style={{ border: '1px solid #797979', borderRadius: "25px" }} />
                </div>
                <div className="lg:w-2/12 ">
                  <button className="cust-button mb-3 ml-5" onClick={filterData}>Apply</button>
                  <button className="cust-button mb-3 ml-5" onClick={resetfilterData}>Reset</button>
                </div>

              </div>
              <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr className="px-6 py-4 font-medium text-blue-600 whitespace-nowrap dark:text-white">
                      <th scope="col" class="px-6 py-3">
                        First Name
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Last Name
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Email
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Phone
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Card No.
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Restaurant
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Date
                      </th>

                    </tr>
                  </thead>
                  <tbody>
                    {EmployeList?.length && EmployeList[0]?.id != null ? (
                      EmployeList?.map((EmployeListVal, i) => {
                        let date = new Date(EmployeListVal?.date);
                        /* Date format you have */
                        let dateMDY = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
                        /* Date converted to MM-DD-YYYY format */
                        return (
                          <tr
                            className="text-black border-b bg-white dark:bg-gray-800 dark:border-gray-700"
                            key={i}
                          >
                            <td className="px-6 py-4">
                              {EmployeListVal?.name}
                            </td>
                            <td className="px-6 py-4">
                              {EmployeListVal?.lastname}
                            </td>
                            <td className="px-6 py-4">
                              {EmployeListVal?.email}
                            </td>
                            <td class="px-6 py-4">
                              {EmployeListVal?.phone_no}
                            </td>
                            <td class="px-6 py-4">
                              {EmployeListVal?.card_no}
                            </td>
                            <td class="px-6 py-4">
                              {EmployeListVal?.restaurantName}
                            </td>
                            <td class="px-6 py-4">
                              {dateMDY && dateMDY}
                            </td>

                          </tr>
                        );
                      })
                    ) : (
                      <tr className="bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 text-center" colSpan={7}>
                          No Record Found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <ReactPaginate
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={totalCount / perPageRecord}
                previousLabel="< previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default SuperEmployeList;
